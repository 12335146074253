import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function Toast({ message, type, openToast, setOpenToast, duration = 6000 }) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    if (message == 'Internal State Error') {
        message = t('internalStateErrorMessage')
    } else if (message == 'Network Error') {
	    message = t('timeoutErrorMessage') 
    }


    return (
        <div className={classes.root}>
            {type === "error" &&
                <Snackbar open={openToast} autoHideDuration={duration} onClose={handleClose}>
                    <Alert severity="error" onClose={handleClose}>{message}</Alert>
                </Snackbar>
            }

            {type === "warning" &&
                <Snackbar open={openToast} autoHideDuration={duration} onClose={handleClose}>
                    <Alert severity="warning" onClose={handleClose}>{message}</Alert>
                </Snackbar>
            }

            {type === "info" &&
                <Snackbar open={openToast} autoHideDuration={duration} onClose={handleClose}>
                    <Alert severity="info" onClose={handleClose}>{message}</Alert>
                </Snackbar>
            }

            {type === "success" &&
                <Snackbar open={openToast} autoHideDuration={duration} onClose={handleClose}>
                    <Alert severity="success" onClose={handleClose}>{message}</Alert>
                </Snackbar>
            }
        </div >
    );
}
