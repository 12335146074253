import React from "react";
import { Grid } from 'semantic-ui-react';
import "./ProfileA.css";
import { useTranslation } from 'react-i18next';
import moment from 'moment';


export default function ProfileInsuranceInformation(props) {
    const { t } = useTranslation();
    return (
        <Grid rows={2} style={{ width: '100%' }} id="two-column-insurance-profile-admin">
            <Grid.Row>
                <Grid stackable columns={4}>
                    <Grid.Row columns={4}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('primary')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyName ? "" : props.dataPrimary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyNumber ? "" : props.dataPrimary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.groupNumber ? "" : props.dataPrimary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.effectiveDate ? "" : moment(props.dataPrimary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={4}>
                    <Grid.Row columns={4}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('secondary')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyName ? "" : props.dataSecondary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyNumber ? "" : props.dataSecondary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.groupNumber ? "" : props.dataSecondary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.effectiveDate ? "" : moment(props.dataSecondary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}