import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Dimmer, Grid, Loader, Icon } from 'semantic-ui-react';
import api from "../../api";
import "./UnauthenticatedAuthenticationTwo.css";
import Toast from "../common/Toast";

const UnauthenticatedAuthenticationTwo = (props) => {
    const { t } = useTranslation();
    
    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            authenticationCode: ""
        }
    });

    function actionButtonSubmit(sub) {
        props.validatePin(getValues());
    }

    return (
        <form className="form-anauthenticated-authentication-two" onSubmit={handleSubmit(actionButtonSubmit)}>
            {props.openToast &&
                <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }
            <Grid rows={5} columns={1} className="body-grid-unauthenticated-authentication-two">
                <Grid.Row centered style={{ paddingTop: '25px' }}>
                    <span className="letter-title-unauthenticated-authentication-two">{t("authentication")}</span>
                </Grid.Row>

                <Grid.Column style={{ paddingLeft: 0 }}>
                    <Grid.Row>
                        <span className="letter-body-unauthenticated-authentication-two">{t("codeSentTo")}</span><br />
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-data-unauthenticated-authentication-two">{props.twoFactorSelected}</span>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Row>
                    <label className="letter-body-unauthenticated-authentication-two">
                        {t("enterAuthenticationCode")} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input
                        className="input-unauthenticated-authentication-two"
                        type="text"
                        id="authenticationCode"
                        name="authenticationCode"
                        ref={register({ required: true })}
                    />
                </Grid.Row>

                {errors.authenticationCode &&
                    <Grid.Row centered>
                        <span className="error-text">{t("AuthenticationCodeEmpty")}</span>
                    </Grid.Row>
                }

                <Grid.Row centered className="padding-botton-grid-unauthenticated-authentication-two">
                    <button type="submit" className="button-circle-unauthenticated-authentication-two">{t("continue")}</button>
                    <Link className="letter-cancel-unauthenticated-authentication-two" to={'/home'}>{t("cancel")}</Link>
                </Grid.Row>
            </Grid>
        </form>
    );
};

export default UnauthenticatedAuthenticationTwo;
