import { createSlice } from '@reduxjs/toolkit'

const { actions, reducer } = createSlice({
  name : 'ui',
  initialState: {
    language: 'en'
  },
  reducers: {
    setLanguage(state, {payload}) {
        state.language = payload;
    },
  },
});

export const { setLanguage } = actions;

export default reducer;