import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import AuditLogHome from "./components/auditLog/AuditLogHome";
import AuthenticatedAuthentication from "./components/common/AuthenticatedAuthentication";
import AuthenticatedHeader from "./components/common/AuthenticatedHeader";
import AuthenticatedNavbarTwo from "./components/common/AuthenticatedNavbarTwo";
import Body from "./components/common/Body";
import FooterAuthenticated from "./components/common/FooterAuthenticated";
import Navbar from "./components/common/Navbar";
import PrivateRoute from './components/common/PrivateRoute';
import DashboardHome from "./components/dashboard/DashboardHome";
import ManageUsersHome from "./components/manageUsers/ManageUsersHome";
import ComponentsProfile from "./components/profile/ComponentsProfile";
import ComponentsUpload from "./components/profile/ComponentsUpload";
import ComponentsChangePassword from "./components/profile/ComponentsChangePassword";
import ProfileAHome from "./components/profileA/ProfileAHome";
import ServiceHistoryDetails from "./components/serviceHistory/ServiceHistoryDetails";
import ServiceHistoryHome from "./components/serviceHistory/ServiceHistoryHome";
import ComponentsForgotPassword from './components/signin/ComponentsForgotPassword';
import ComponentsForgotSurname from './components/signin/ComponentsForgotSurname';
import ComponentsRegister from './components/signin/ComponentsRegister';
import Login from "./components/signin/Login";
import LoginAdmin from "./components/signin/LoginAdmin";
import store from './redux';
import GAListener from "./components/common/GAListener";

export default function App() {

    const { i18n } = useTranslation();
    const [openToast, setOpenToast] = useState(false);

    useEffect(() => {
        var lang = localStorage.getItem('language');
        if (!lang) {
            lang = 'en';
            localStorage.setItem('language', 'en');
        }
        i18n.changeLanguage(lang);
    }, []);

    return (
        <Provider store={store}>

            <Router>
	    	<GAListener trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/home" />} />

                        <Route path="/home" render={() => <Body />} />

                        <Route path="/signin" render={() => <Body><Login openToast={openToast} setOpenToast={setOpenToast} path={"/login"} /></Body>} />

                        <Route path="/register" render={() => <Body><ComponentsRegister openToast={openToast} setOpenToast={setOpenToast} /></Body>} />

                        <Route path="/forgot-password" render={() => <Body><ComponentsForgotPassword openToast={openToast} setOpenToast={setOpenToast} /></Body>} />

                        <Route path="/forgot-username" render={() => <Body><ComponentsForgotSurname openToast={openToast} setOpenToast={setOpenToast} /></Body>} />

                        <Route exact path="/admin" render={() => <Redirect to="/admin/signin" />} />

                        <Route path="/admin/signin" render={() => <Body admin={true}><LoginAdmin openToast={openToast} setOpenToast={setOpenToast} admin={true} /></Body>} />

                        <PrivateRoute path="/dashboard">
                            <AuthenticatedHeader />
                            <Navbar />
                            <DashboardHome openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/profile-upload-primary">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ComponentsUpload openToast={openToast} setOpenToast={setOpenToast} title={"primary"} path={"/unauthenticated-authenticationTwo-password"} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/profile-upload-secondary">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ComponentsUpload openToast={openToast} setOpenToast={setOpenToast} title={"secondary"} path={"/unauthenticated-authenticationTwo-password"} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/profile">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ComponentsProfile openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/profile-change-password">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ComponentsChangePassword openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/service-history">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ServiceHistoryHome openToast={openToast} setOpenToast={setOpenToast} isSiteA={false} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute path="/service-history-details/:ticketNumber">
                            <AuthenticatedHeader />
                            <Navbar />
                            <ServiceHistoryDetails openToast={openToast} setOpenToast={setOpenToast} isSiteA={false} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/audit-log">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <AuditLogHome openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/audit-log/:patientId">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <AuditLogHome openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/manage-users">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <ManageUsersHome openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/profile-A">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <ProfileAHome openToast={openToast} setOpenToast={setOpenToast} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/service-history-A">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <ServiceHistoryHome openToast={openToast} setOpenToast={setOpenToast} isSiteA={true} />
                            <FooterAuthenticated />
                        </PrivateRoute>

                        <PrivateRoute admin={true} path="/service-history-details-A/:patientId/:ticketNumber">
                            <AuthenticatedHeader />
                            <AuthenticatedNavbarTwo />
                            <ServiceHistoryDetails openToast={openToast} setOpenToast={setOpenToast} isSiteA={true} />
                            <FooterAuthenticated />
                        </PrivateRoute>
                    </Switch>
	    </GAListener>
            </Router>

        </Provider>
    );
}
