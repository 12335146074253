import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dimmer, Grid, Loader, Icon } from 'semantic-ui-react';
import api from "../../api";
import "./ForgotPassword.css";
import Toast from "../common/Toast";

const ForgotPassword = (props) => {
    const { t } = useTranslation();
    const [loadingActive, setLoadingActive] = useState(false);
    const [notFoundUser, setNotFoundUser] = useState(false);
    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            userName: undefined,
            captcha: "test code"
        }
    })

    function actionButtonSubmit() {
        let values = getValues();

        let body = {
            Username: values.userName,
            "ChallengeResponse": {
                "Type": "CAPTCHA",
                "ReCAPTCHAResponse": { "Answer": values.captcha }
            }
        }

        setLoadingActive(true);
        setNotFoundUser(false);
        api.userUpdatePost(body)
            .then((response) => {
                if (response.data.code === "Success") {
                    api.setSessionToken(response.data.SessionToken);
                    props.setUsername(values.userName);
                    props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                    setLoadingActive(false);
                    props.nextPageForgotPassword();
                }
                else {
                    setLoadingActive(false);
                }
            }).catch((err) => {
                setLoadingActive(false);
                window.grecaptcha.reset();
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                    if (err.response.status === 401) {
                        setNotFoundUser(true);
                    } else {
                        props.setOpenToast(true);
                    }
                }
                else {
                    props.setMessageError(err.message)
                    props.setOpenToast(true);
                }

            })
    }

    const solveCaptcha = (response) => {
        setValue('captcha', response);
    }

    return (
        <form className="forgotPasswordForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid rows={6} columns={1} className="body-grid-forgot-password">
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

                {props.openToast &&
                    <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
                }

                <Grid.Row centered>
                    <span className="login-title">{t("forgotPassword_title")}</span>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-forgot-password">
                        {t("userName")} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input aria-label={t("userName")} type="text" id="userName" name="userName" ref={register({ required: true })} className="input-forgot-password" />
                </Grid.Row>

                <Grid.Row className="recaptcha-forgot-password text-center">
                    <input aria-label='captchaInput' type="hidden" name="captcha" ref={register({ required: true })}></input>
                    <ReCAPTCHA
                        aria-label='captcha'
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={solveCaptcha}
                    />
                </Grid.Row>

                {errors.userName &&
                    <Grid.Row centered>
                        <span className="error-text">{t('requiredField')}</span>
                    </Grid.Row>
                }

                {notFoundUser &&
                    <Grid.Row centered>
                        <Grid.Column style={{ marginLeft: 28, marginRight: 28 }}><span className="error-text">{t('forgotPassword_notFoundUser')}</span></Grid.Column>
                    </Grid.Row>
                }


                {errors.captcha &&
                    <Grid.Row centered>
                        <span className="error-text">{t("error_captcha_required")}</span>
                    </Grid.Row>
                }

                <Grid.Row centered>
                    <button type="submit" className="button-circle-forgot-password" name="recoverPassword" id="recoverPassword">
                        {t("submit")}
                    </button>
                    <Link className="letter-cancel-forgot-password" to={"/signin"}>{t("cancel")}</Link>
                </Grid.Row>
            </Grid>
        </form>
    );
};

export default ForgotPassword;