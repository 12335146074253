import React from "react";
import { post } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Grid } from 'semantic-ui-react';
import "./Profile.css";

export default function ProfileConfirmEdit(props) {
    const { t } = useTranslation();
    const history = useHistory();

    function handleClick() {
        let url = window.location.href;
        if (url.includes('profile-change-password') || url.includes('profile-upload-primary') || url.includes('profile-upload-secondary')) {
            history.replace('profile');
        }
        else {
            props.resetPages();
        }
    }

    return (
        <Grid rows={2} className="body-center confirm" style={{paddingTop:'150px',paddingBottom:'150px'}}>
            <Grid.Row centered>
                <span className="title-upload-card-confirm">{t('textProfileConfirm1')}</span>
                <span className="title-upload-card-confirm">{t('textProfileConfirm2')}</span>
                <span className="title-upload-card-confirm">{t('textProfileConfirm3')}</span>
            </Grid.Row>

            <Grid.Row centered>
                <button onClick={handleClick} className="button-circle" name="continue" id="continue" style={{ width: '19%', height: '37px', margin: 0 }}>Continue</button>
            </Grid.Row>
        </Grid>
    );
}