import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import "./LoginAdmin.css";
import api from "../../api";
import queryString from 'query-string';
import { setAuth } from "../../redux/auth";
import Toast from "../common/Toast";

const LoginAdmin = (props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [loadingActive, setLoadingActive] = useState(false);
    const [messageError, setMessageError] = useState();
    const [envVariables, setEnvVariables] = useState('');

    function handleClick() {
        window.location = api.urlRedirectAdmin();
    }

    function handleClickMock(){
        window.location = process.env.REACT_APP_MOCK_ADMIN_LOGIN_URL;
    }

    function handleLogout() {
	window.location = api.urlRedirectAdminLogout();
    }

    React.useEffect(() => {
        if (process.env.REACT_APP_MOCK_ADMIN_LOGIN_ENABLED=='true') {
            console.log(process.env)
            setEnvVariables(JSON.stringify(process.env));
        }
    }, []);


    React.useEffect(() => {
        if (auth.authenticated) {
            history.replace('/manage-users');
        }
    }, [auth.authenticated]);

    React.useEffect(() => {
        window.localStorage.setItem("SessionToken", "-");
        api.setSessionToken('-');
        let url = history.location.search;
        let params = queryString.parse(url);
        if (params.SamlResponse) {
            setLoadingActive(true);
            api.postSignInAdmin(params.SamlResponse).then((resp) => {
                setLoadingActive(false);
                window.localStorage.setItem("SessionToken", resp.data.SessionToken);
                window.localStorage.setItem('loggedIn', "true");   
                window.localStorage.setItem('loggedInAdmin', "true");    
                api.setSessionToken(resp.data.SessionToken);
                dispatch(setAuth({ token: resp.data.SessionToken, admin: true }));
            }).catch((err) => {
                setLoadingActive(false);
                api.logout();
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
            })
        } else if (params.token) {
            setLoadingActive(true);
            window.localStorage.setItem("SessionToken", params.token);
            api.postCheckValidAdmin().then((resp) => {
                setLoadingActive(false)
                window.localStorage.setItem('loggedIn', "true");   
                window.localStorage.setItem('loggedInAdmin', "true");   
                api.setSessionToken(resp.data.SessionToken);
                dispatch(setAuth({ token: resp.data.SessionToken, admin: true }));
            }).catch((err) => {
                setLoadingActive(false);
                api.logout();
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
            })

            // setLoadingActive(false);
        }
        if(params.sessionExpired){
            setMessageError(t('sessionExpiredMessage'));
            props.setOpenToast(true);
        }
    }, []);

    return (
        <Grid columns={1} rows={2} className="body-grid-login-admin">
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Signing in...</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid.Row centered>
                <span className="login-admin-title">{t("titleSignin")}</span>
            </Grid.Row>

            <Grid.Row centered className="adminLoginButton" style={{ paddingBottom: '30px' }}>
                <button className="button-circle-login-admin" type="button" onClick={handleClick} name="login-admin" id="login-admin">
                    {t("signin")}
                </button>
            </Grid.Row>
            {process.env.REACT_APP_MOCK_ADMIN_LOGIN_ENABLED == 'true' ?             
            <Grid.Row centered style={{ paddingBottom: '30px' }}>
                <button className="button-circle-login-admin" type="button" onClick={handleClickMock} name="login-admin" id="login-admin">
                    {t("signin")} - Mock Admin Login
                </button>
            </Grid.Row>
            : null}

        </Grid>
    );
};

export default LoginAdmin;
