import React from "react";
import { Grid } from 'semantic-ui-react';
import "./ProfileA.css";
import { useTranslation } from 'react-i18next';

export default function ProfilePatientProfile(props) {
    const { t } = useTranslation();
    return (
        <Grid rows={3} id="two-column-patient-profile-admin">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('firstName')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.firstName ? "" : props.dataPatient.firstName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('lastName')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.lastName ? "" : props.dataPatient.lastName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phoneNumber')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.phoneNumber ? "" : props.dataPatient.phoneNumber}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('mobile')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.mobile ? "" : props.dataPatient.mobile}</p>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={8} className="size-column-profile">
                            <span className="font-title-body">{t('deliveryAddress')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.address ? "" : props.dataPatient.address}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('city')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.city ? "" : props.dataPatient.city}</p>
                        </Grid.Column>

                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('state')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.state ? "" : props.dataPatient.state}</p>
                        </Grid.Column>

                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('zipCode')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.zipCode ? "" : props.dataPatient.zipCode}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile column-responsive-email-address-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.emailId ? "" : props.dataPatient.emailId}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.emailType ? "" : props.dataPatient.emailType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}
