import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { Grid, Header, Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch } from "react-redux";
import { setUnAuth } from "../../redux/auth";
import "./Navbar.css";
import api from '../../api';

export default function AuthenticatedNavbarTwo() {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loadingActive, setLoadingActive] = React.useState();

    const viewButtonManageSelected = () => {
        let url = window.location.href;
        if (url.includes('manage') || url.includes('service-history') || url.includes('profile-A')) {
            return true;
        }
        else {
            return false;
        }
    }

    const viewButtonAuditSelected = () => {
        let url = window.location.href;
        if (url.includes('audit')) {
            return true;
        }
        else {
            return false;
        }
    }

    function actionLogOut() {
        api.logout().then(() => {
            setLoadingActive(false);
            window.localStorage.removeItem("SessionToken");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("loggedIn");
            window.localStorage.removeItem("loggedInAdmin");
            window.localStorage.removeItem("userFirstName");
            dispatch(setUnAuth());
            history.replace("/admin");
        }).catch((err) => {
            setLoadingActive(false);
            window.localStorage.removeItem("SessionToken");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("loggedIn");
            window.localStorage.removeItem("loggedInAdmin");
            window.localStorage.removeItem("userFirstName");
            dispatch(setUnAuth());
            history.replace("/admin");
        });
    }

    const actionButtons = (url) => {
        history.replace(url);
    }

    return (
        <Header as='h2' className="color-no-margin-header margin-navbar">
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader>{t('loggingOut')}</Loader>
                </Dimmer>
            }
            {localStorage.getItem('language') == 'en'
                ?
                <Grid columns={3}>
                    <Grid.Column className={viewButtonManageSelected() ? "row-selected-navbar-two width-manage-navbar-two" : "width-manage-navbar-two"}>
                        <Link to={'/manage-users'}
                            className={viewButtonManageSelected() ? "button-selected-navbar-two" : "font-button-navbar position-button-navbar"}>
                            {t('MANAGEUSERS')}</Link>
                    </Grid.Column>
                    <Grid.Column className={viewButtonAuditSelected() ? "row-selected-navbar-two width-audit-navbar-two" : "width-audit-navbar-two"}>
                        <Link to={'/audit-log'}
                            className={viewButtonAuditSelected() ? "button-selected-navbar-two" : "font-button-navbar position-button-navbar"}>
                            {t('AUDITLOG')}</Link>
                    </Grid.Column>
                    <Grid.Column floated='right' className="width-audit-navbar-two text-aling-sign-out-navbar-two">
                        <a onClick={actionLogOut}
                            className="font-button-navbar position-button-navbar">
                            {t('SIGNOUT')}</a>
                    </Grid.Column>
                </Grid>

                :

                <Grid columns={3}>
                    <Grid.Column className={viewButtonManageSelected() ? "size-column-spanish row-selected-navbar-two" : "size-column-spanish"}>
                        <Link to={'/manage-users'}
                            className={viewButtonManageSelected() ? "button-selected-navbar-two" : "font-button-navbar position-button-navbar"}>
                            {t('MANAGEUSERS')}</Link>
                    </Grid.Column>
                    <Grid.Column className={viewButtonAuditSelected() ? "row-selected-navbar-two size-column-spanish" : "size-column-spanish"}>
                        <Link to={'/audit-log'}
                            className={viewButtonAuditSelected() ? "button-selected-navbar-two" : "font-button-navbar position-button-navbar"}>
                            {t('AUDITLOG')}</Link>
                    </Grid.Column>
                    <Grid.Column floated='right' className="width-sign-out-navbar text-aling-sign-out-navbar-two">
                        <Link to={''} onClick={actionLogOut}
                            className="font-button-navbar position-button-navbar">
                            {t('SIGNOUT')}</Link>
                    </Grid.Column>
                </Grid>
            }
        </Header>
    );
}