import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Dimmer, Grid, Loader, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import api from "../../api";
import { DateInput } from 'semantic-ui-calendar-react';
import { setAuth } from "../../redux/auth";
import "./Profile.css";
import moment from 'moment';

export default function ProfileManualUpload(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [loadingActive, setLoadingActive] = useState(false);
    const dispatch = useDispatch();
    const [primary, setPrimary] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState();
    const auth = useSelector(state => state.auth);

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            company: undefined,
            policyNumber: undefined,
            groupNumber: undefined,
        }
    });

    const handleDateChange = (event, { name, value }) => {
        setEffectiveDate(value);
        setValue('effectiveDate', value);
    };

    useEffect(() => {
        if (!auth.profile) {
            setLoadingActive(true);
            api.patientGetGet({ PatientId: "", "GetProfile": true }).then((profResp) => {
                dispatch(setAuth({ profile: profResp.data.Profile }));
                setLoadingActive(false);
            }).catch((err) => {
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                setLoadingActive(false);
            })
        }
        setPrimary(window.location.href.includes('primary'));
    }, []);

    function clickCancel() {
        history.replace("/profile");
    }

    function actionButtonSubmit() {
        let values = getValues();
        //ToDo: Consultar por URL
        props.setUploadCard(false);
        let key = 'primaryInsuranceInformation';
        if (!primary) {
            key = 'secondaryInsuranceInformation';
        }

        let body = { Profile: { ...auth.profile } };
        body.Profile[key] = {
            "policyName": values.company,
            "policyNumber": values.policyNumber,
            "groupNumber": values.groupNumber,
            "effectiveDate": moment(values.effectiveDate, ["MM-DD-YYYY"]).toDate()
        };
        setLoadingActive(true);
        props.setBodyManualUpload(body);
        api.patientUpdatePost(body)
            .then((response) => {
                props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                if (!response.data.ChallengeRequest.ContactOptionList) {
                    setLoadingActive(false);
                    history.replace("/profile");
                }
                else {
                    setLoadingActive(false);
                    props.setPageComponentsUpload('AuthenticatedAuthentication');
                }
            }).catch((err) => {
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
    }

    return (
        <form className="profileManualUploadForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid rows={7} columns={1} width={4} centered>
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

                <Grid.Row className="title">
                    {primary
                        ?
                        <span className="title-upload-manual">{t('upload_primary')} <Icon className="mandatoryField" name='asterisk' /></span>
                        :
                        <span className="title-upload-manual">{t('upload_secondary')} <Icon className="mandatoryField" name='asterisk' /></span>
                    }
                </Grid.Row>

                <Grid.Row>
                    <span className="letter-upload-manual padding-responsive-text-upload-manual">{t('upload_description')}</span>
                </Grid.Row>

                <Grid.Column className="changePasswordProfile">
                    <Grid.Row className="margin-rows-upload-manual">
                        <label className="font-title-body">{t('upload_company')} <Icon className="mandatoryField" name='asterisk' /></label>
                        <p><input aria-label={t('upload_company')} id="company" name="company" ref={register({ required: true })} /></p>
                    </Grid.Row>

                    {errors.company &&
                        <Grid.Row centered className="margin-message-error-upload-manual">
                            <span className="error-text">{t("error_company_required")}</span>
                        </Grid.Row>
                    }

                    <Grid.Row className="margin-rows-upload-manual">
                        <label className="font-title-body">{t('upload_policy')} <Icon className="mandatoryField" name='asterisk' /></label>
                        <p><input aria-label={t('upload_policy')} id="policyNumber" name="policyNumber" ref={register({ required: true })} /></p>
                    </Grid.Row>

                    {errors.policyNumber &&
                        <Grid.Row centered className="margin-message-error-upload-manual">
                            <span className="error-text">{t("error_policy_required")}</span>
                        </Grid.Row>
                    }


                    <Grid.Row className="margin-rows-upload-manual">
                        <label className="font-title-body">{t('upload_group')} <Icon className="mandatoryField" name='asterisk' /></label>
                        <p><input aria-label={t('upload_group')} id="groupNumber" name="groupNumber" ref={register({ required: true })} /></p>
                    </Grid.Row>

                    {errors.groupNumber &&
                        <Grid.Row centered className="margin-message-error-upload-manual">
                            <span className="error-text">{t("error_groupNumber_required")}</span>
                        </Grid.Row>
                    }


                    <Grid.Row className="margin-rows-upload-manual">
                        <label className="font-title-body">{t('upload_effective')} <Icon className="mandatoryField" name='asterisk' /></label>
                        <input aria-label='effectiveDate' type="hidden" id="effectiveDate" name="effectiveDate" ref={register({ required: true })} />
                        <DateInput
                            aria-label={t('upload_effective')}
                            dateFormat="MM-DD-YYYY"
                            placeholder={t('upload_effective')}
                            closeOnMouseLeave={false}
                            closable={true}
                            name="effectiveDateUpload"
                            id="effectiveDateUpload"
                            iconPosition="left"
                            className="date-filter max-width padding-datepicker-manual-upload"
                            value={effectiveDate}
                            animation="none"
                            onChange={handleDateChange}
                            ref={register({ required: true })}
                        />
                    </Grid.Row>

                    {errors.effectiveDate &&
                        <Grid.Row centered className="margin-message-error-upload-manual">
                            <span className="error-text">{t("error_effectiveDateUpload_required")}</span>
                        </Grid.Row>
                    }

                    <Grid.Row className="margin-rows-upload-manual">
                        <button type="submit" className=" button-circle buttons-upload-manual" style={{ marginRight: 90 }} name="save" id="save">{t('save')}</button>
                        <button className="button-circle buttons-upload-manual" onClick={clickCancel} name="cancel" id="cancel">{t('cancel')}</button>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </form>
    );
}
