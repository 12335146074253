import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Icon, Pagination, Table, Message } from 'semantic-ui-react';
import moment from 'moment';

const ServiceHistoryList = ({ page, isSiteA, serviceList, filterList, pagination, setPage, sortColumn, sortDirection, setSortColumn, setSortDirection }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [patientId, setPatientId] = React.useState(new URLSearchParams(useLocation().search).get("patientId"));

    function handleClick(ticketNumber) {
        if (isSiteA) {
            history.push("/service-history-details-A/" + patientId + '/' + ticketNumber);
        }
        else {
            history.push("/service-history-details/" + ticketNumber);
        }
    }

    const toggleSort = (column) => {
        if (sortColumn === column) {
            if (sortDirection === 'asc') {
                setSortDirection('desc');
            } else {
                setSortDirection('asc');
            }
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    }

    const onPageChange = (evt, data) => {
        if (page != data.activePage) {
            setPage(data.activePage);
        }
    }

    const TableRow = ({ item }) => {
        return <Table.Row key={item.ticketNumber}>
            <Table.Cell>{item.ticketNumber}</Table.Cell>
            <Table.Cell>{moment(item.dateOfService).format("MM-DD-YYYY")}</Table.Cell>
            <Table.Cell>{item.orderType}</Table.Cell>
            <Table.Cell>{item.status}</Table.Cell>
            <Table.Cell>{item.servicingLocation}</Table.Cell>
            <Table.Cell><a style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.ticketNumber) }}><Icon name='copy outline' size='large' /><strong style={{ fontSize: '16px' }}>{t('viewDetails')}</strong></a></Table.Cell>
        </Table.Row>
    };
    return (
        <Grid padded='vertically' className="margin-list">
            <Grid.Column>
                <Table id="tableServiceHistory" celled fixed singleLine compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => { toggleSort('ticketNumber') }} className="letter-title-service-history-list">
                                {t('ticketNumber')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "ticketNumber" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "ticketNumber" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>

                            <Table.HeaderCell  onClick={() => { toggleSort('dateOfService') }} className="letter-title-service-history-list">
                                {t('dateOfService')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "dateOfService" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "dateOfService" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>

                            <Table.HeaderCell onClick={() => { toggleSort('orderType') }} className="letter-title-service-history-list">
                                {t('orderType')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "orderType" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "orderType" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>

                            <Table.HeaderCell  onClick={() => { toggleSort('status') }} className="letter-title-service-history-list">
                                {t('status')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "status" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "status" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>

                            <Table.HeaderCell onClick={() => { toggleSort('servicingLocation') }} className="letter-title-service-history-list">
                                {t('servicingLocation')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "servicingLocation" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "servicingLocation" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {serviceList.map((itm) => {
                            return (<TableRow key={itm.ticketNumber} item={itm}></TableRow>);
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell textAlign='left' style={{ position: 'relative' }}>
                                <p className="pageCount">{t('pageNumber')} {pagination.PageNumber} {t('pageOf')} {pagination.TotalPages}</p>
                            </Table.HeaderCell>

                            <Table.HeaderCell colSpan='5' textAlign='right' style={{ position: 'relative' }}>
                                <Pagination
                                    aria-label='Pagination'
                                    style={{ marginRight: '2em' }}
                                    activePage={pagination.PageNumber}
                                    totalPages={pagination.TotalPages}
                                    firstItem={null}
                                    lastItem={null}
                                    ellipsisItem={null}
                                    onPageChange={onPageChange}
                                    nextItem={t('next')}
                                    prevItem={t('prev')}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid>
    );
}

export default ServiceHistoryList;
