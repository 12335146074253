import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from "react-router-dom";
import { setLocation } from '../../redux/auth';

const PrivateRoute = ({ admin, children, ...rest }) => {
    const auth = useSelector(state => state.auth)
    const location = useLocation();
    const dispatch = useDispatch();

    let defaultRoute = "/dashboard";
    
    useEffect(()=> {
      if(auth.admin || window.localStorage.getItem('loggedInAdmin')){


      }
      dispatch(setLocation(location.pathname));
    }, []);
    
    useEffect(()=> {
      if(auth.admin){
        defaultRoute = "/manage-users";
      } else {
        defaultRoute = "/dashboard"
      }
    }, [auth.authenticated]);

    return (
      <Route
        {...rest}
        render={({ location }) =>
            auth.authenticated || window.localStorage.getItem("SessionToken") ? (
                (!auth.admin && !admin) || (window.localStorage.getItem("loggedInAdmin")  && admin) ?
                    children :
            <Redirect
              to={{
                pathname: defaultRoute
              }}
            /> 
            ) : (
            <Redirect
              to={{
                pathname: "/signin"
              }}
            />
          )
        }
      />
    );
  };

  export default PrivateRoute;