import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Grid, Icon } from 'semantic-ui-react';
import api from "../../api";
import "./ResetPassword.css";
import Toast from "../common/Toast";

const ResetPassword = (props, { setMessageError, setOpenToast, username, setLoadingActive }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [errorPass, setErrorPass] = useState(false);

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            password: undefined,
            reEnterPassword: undefined,
        }
    });

    function actionButtonSubmit(sub) {
        let values = getValues();
        let body = {
            "Password": values.password.trim(),
            "Username": props.username
        }

        let passwordValidation = validationPassword(values.password.trim(), values.reEnterPassword.trim());
        if (!passwordValidation) {
            setErrorPass(true);
        }
        else {
            props.setLoadingActive(true);
            api.resetPassword(body).then(() => {
                props.setLoadingActive(false);
                history.replace('/signin');
            }).catch((err) => {
                props.setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
        }
    }

    function validationPassword(pass, reEnterPass) {
        if (!pass || !reEnterPass) {
            return false;
        }
        var request = true;
        if (pass.length < 8) {
            request = false;
        }

        var hasNumber = /\d/;
        if (!hasNumber.test(pass)) {
            request = false;
        }

        var hasUppercase = /[A-Z]|[\u0080-\u024F]/;
        if (!hasUppercase.test(pass)) {
            request = false;
        }

        if (pass != reEnterPass) {
            request = false;
        }

        return request;
    }

    return (
        <form className="resetPasswordForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            {props.openToast &&
                <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }
            <Grid columns={1} rows={7} className="body-grid-reset-account-password">
                <Grid.Row centered>
                    <span className="letter-title-reset-account-password">Reset Password</span>
                </Grid.Row>

                <Grid.Column>
                    <Grid.Row className="padding-row-requeriment">
                        <span className="letter-requeriment-title-reset-account-password">{t("passwordCriteria0")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-reset-account-password">{t("passwordCriteria1")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-reset-account-password">{t("passwordCriteria2")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-reset-account-password">{t("passwordCriteria3")}</span>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Row>
                    <label className="letter-body-reset-account-password">
                        {t("enterNewPassword")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <input type="password" ref={register({ required: true })} id="password" name="password" className="input-reset-account-password" />
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-reset-account-password">
                        {t("reEnterNewPassword")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <input type="password" ref={register({ required: true })} id="reEnterPassword" name="reEnterPassword" className="input-reset-account-password" />
                </Grid.Row>

                {errors.password &&
                    <>
                        <span className="letter-error-reset-account-password">{t("IncompletePasswordField")}</span>
                    </>
                }

                {errors.reEnterPassword &&
                    <>
                        <span className="letter-error-reset-account-password">{t("IncompleteRe-enterPasswordField")}</span>
                    </>
                }

                {errorPass &&
                    <>
                        <span className="letter-error-reset-account-password">{t("passwordsDoNotMatch")}</span>
                    </>
                }

                <Grid.Row centered>
                    <button type="submit" className="button-circle-reset-account-password">{t("submit")}</button>
                    <Link className="letter-cancel-reset-account-password" to={'/signin'}>
                        {t("cancel")}
                    </Link>
                </Grid.Row>
            </Grid>
        </form>
    );
};

export default ResetPassword;