import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DashboardCards from "./DashboardCards";
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import api from '../../api';
import Toast from "../common/Toast";

export default function DashboardHome(props) {
    const { t, i18n } = useTranslation();
    const [loadingActive, setLoadingActive] = useState();
    const [dataDashboard, setDataDashboard] = useState();
    const [messageError, setMessageError] = useState();

    const loadDashboard = () => {
        window.scrollTo(0, 0);
        setLoadingActive(true);


        api.patientGetGet({ PatientId: "", "GetProfile": null, "GetNotifications": true }).then((profResp) => {
            if(!profResp.data.Notifications.servicingLocation){
                profResp.data.Notifications.servicingLocation = "";
            }
            if(!profResp.data.Notifications.appointments){
                profResp.data.Notifications.appointments = [];
            }
            if(!profResp.data.Notifications.messages){
                profResp.data.Notifications.messages = [];
            }
            if(!profResp.data.Notifications.alerts){
                profResp.data.Notifications.alerts = [];
            }
            setDataDashboard(profResp.data.Notifications);
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            setDataDashboard({ "servicingLocation": "", "appointments": [], messages: [], alerts: [] });
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    };

    useEffect(() => {
        loadDashboard();
    }, [i18n.language]);

    useEffect(() => {
        loadDashboard();
    }, []);

    const [principalCards, setPricipalCards] = useState([]);
    const [secondaryCards, setSecondaryCards] = useState([])

    const [infoCards, setInfoCards] = useState([{
        title: "dashboard_cards_educational_materials_title",
        content: "dashboard_cards_educational_materials_content",
        titleImg: "dashboard_cards_educational_materials_title_img",
        link: process.env.REACT_APP_EDUCATION_MATERIALS_URL,
        nameImg: "u704"
    },
    {
        title: "dashboard_cards_payment_title",
        content: "dashboard_cards_payment_content",
        titleImg: "dashboard_cards_payment_title_img",
        link: process.env.REACT_APP_MAKE_A_PAYMENT_URL,
        nameImg: "u706"
    },
    {
        title: "dashboard_cards_shop_title",
        content: "dashboard_cards_shop_content",
        titleImg: "shopRotech",
        link: process.env.REACT_APP_SHOP_URL,
        nameImg: "shopRotech"
    },
    {
        title: "dashboard_cards_cpap_title",
        content: "dashboard_cards_cpap_content",
        titleImg: "dashboard_cards_cpap_title_img",
        link: process.env.REACT_APP_CPAP_URL,
        nameImg: "u705"
    }
	])


    return (
        <>
            {
                loadingActive ?
                    <Dimmer active inverted>
                        <Loader size='big' > Loading</Loader>
                    </Dimmer >
                    :
                    dataDashboard &&
                    <>
                        <DashboardCards dataAppointments={dataDashboard.appointments} servicingLocations={dataDashboard.servicingLocation}
                            messages={dataDashboard.messages} alerts={dataDashboard.alerts}
                            principalCards={principalCards} secondaryCards={secondaryCards} infoCards={infoCards}></DashboardCards>

                        {props.openToast &&
                            <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
                        }
                    </>
            }
        </>
    );
}
