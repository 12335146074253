import { Link } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react'
import { Button, Header, Modal, Dimmer, Loader} from 'semantic-ui-react'
import api from '../../api';
import Toast from "../common/Toast";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

const InstamedSsoModal = ({label}) => {
    const [open, setOpen] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState();
    const [openToast, setOpenToast] = useState(false);
    const [orderUrl, setOrderUrl] = useState();
    const [showInline, setShowInline] = useState(false);
    const { t } = useTranslation();

    const navigateToStaticOrderPage = () => {
        ReactGA.event({ category: 'Ordering', action: 'Order', label: 'Open Static Order Page'});
        window.open(process.env.REACT_APP_CPAP_URL, '_blank', 'noreferrer');
        setOpen(false);
    }

    const cancelCloseAndReset = () => {
        ReactGA.event({ category: 'Ordering', action: 'S3', label: 'Cancelled Order to S3'});
        closeAndReset();
    }

    const closeAndReset = () => {
        setOrderUrl("");
        setShowInline(false);
        setConfirmationOpen(false);
        setOpen(false);
    }

    const attemptOrderLinkUrl = () => {
        setOpen(true);
        setLoading(true);

        api.orderLinkUrl().then((response) => {
            let url = response?.data?.url;

            if (url === undefined) {
                navigateToStaticOrderPage();
            } else {
                ReactGA.event({ category: 'Ordering', action: 'S3', label: 'Open S3'});
                setOrderUrl(url);
                setShowInline(true);
            }

            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
            setOpen(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            setOpenToast(true);
        })
    }

    const onMessageReceived = useCallback(event => {
        if (event?.data?.source !== "S3Portal") return;

        if (event?.data?.message == "Order Form Submitted") {
            ReactGA.event({ category: 'Ordering', action: 'S3', label: 'Submitted Order to S3'});
            // Submission, so close it
            closeAndReset();
        }
    })

    useEffect(() => {
        window.addEventListener("message", onMessageReceived);
        return () => window.removeEventListener("message", onMessageReceived);
    }, [onMessageReceived]);

    return (
        <>
        { loading ?
            <Dimmer active inverted>
                <Loader size='big' > Loading</Loader>
            </Dimmer >
            : <>
                <Modal
                    open = {confirmationOpen}
                    size = {'small'}
                >
                    <Modal.Header>{t('nav_s3_confirm_close')}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>
                            {t('nav_s3_confirm_close_detail')}
                            </p>
                            </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button 
                            color='black' 
                            positive
                            onClick={() => setConfirmationOpen(false)}
                        >{t('confirmation_no')}</Button>
                        <Button
                            content={t('confirmation_yes')}
                            onClick={() => cancelCloseAndReset()}
                            loading={loading}
                        />
                    </Modal.Actions>
                </Modal>
            <Modal
                onClose = {() => setConfirmationOpen(true)}
                onOpen = {() => attemptOrderLinkUrl()}
                open = {open}
                centered = {true}
                size = { showInline ? 'fullscreen' : 'medium' }
                trigger={<Button class="letter-title-img-dashboard" as={Link}>{t('dashboard_cards_cpap_title_img')}</Button>}
            >
                    { showInline ? 
                        <>
                            <Modal.Content>
                                <iframe style={{width: '100%', height: '70vh', border: '0px'}} src={orderUrl} />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='black' onClick={() => setConfirmationOpen(true)}>{t('cancel')}</Button>
                            </Modal.Actions>
                        </> : <>
                            <Modal.Header>{t('leaving_patient_portal')}</Modal.Header>
                            <Modal.Content>
                                <Modal.Description>
                                    <Header>{t('nav_s3_order')}</Header>
                                    <p>
                                    {t('nav_s3_order_detail')}
                                    </p>
                                    </Modal.Description>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='black' onClick={() => setOpen(false)}>{t('cancel')}</Button>
                                <Button
                                    content={t('continue')}
                                    onClick={() => navigateToStaticOrderPage()}
                                    positive
                                    loading={loading}
                                />

                            </Modal.Actions>
                        </>
                    }
            </Modal>
            </>
            }
            {openToast &&
                <Toast message={messageError} type="error" openToast={openToast} setOpenToast={setOpenToast} />
            }
        </>
        
    )
}

export default InstamedSsoModal;