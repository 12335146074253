import { createSlice } from '@reduxjs/toolkit'

const { actions, reducer } = createSlice({
    name: 'createAccount',
    initialState: {
        patientId: null,
        dateOfBirth: {
            month: null,
            day: null,
            year: null
        },
        lastName: null,
        userId: null,
        newPassword: null,
        enterNewPassword: null
    },
    reducers: {
        changePatientIdCreateAccount(state, { payload }) {
            state.patientId = payload;
        },

        changeDateOfBirthCreateAccount(state, { payload }) {
            state.dateOfBirth = payload;
        },

        changeLastNameCreateAccount(state, { payload }) {
            state.lastName = payload;
        },

        changeUserIdCreateAccount(state, { payload }) {
            state.userId = payload;
        },

        changeNewPasswordCreateAccount(state, { payload }) {
            state.newPassword = payload;
        },

        changeEnterNewPasswordCreateAccount(state, { payload }) {
            state.enterNewPassword = payload;
        },
    },
});

export const { changePatientIdCreateAccount,
    changeDateOfBirthCreateAccount,
    changeLastNameCreateAccount,
    changeUserIdCreateAccount,
    changeNewPasswordCreateAccount,
    changeEnterNewPasswordCreateAccount } = actions;

export default reducer;