import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from 'semantic-ui-react';
import mySvg from "../../img/PatientPortalLogo.svg";
import u66 from "../../img/u66.png";
import u68 from "../../img/u68.png";
import u69 from "../../img/u69.png";
import "./AuthenticatedHeader.css";
import api from '../../api';

export default function AuthenticatedHeader() {
    const Auth = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const [lang, setLang] = React.useState('en');
    const dispatch = useDispatch();
    const loggedAdmin = window.localStorage.getItem('loggedInAdmin');

    const changeLang = (evt) => {
        localStorage.setItem('language', evt.target.value);
        i18n.changeLanguage(evt.target.value);
        api.setLanguage(evt.target.value);
        setLang(evt.target.value);
    };

    React.useEffect(() => {
        var lang = localStorage.getItem('language');
        if (!lang) {
            lang = 'en';
            localStorage.setItem('language', 'en');
        } else {
            setLang(lang);   
        }
        i18n.changeLanguage(lang);
    }, []);

    return (
        <Fragment>
            <Grid columns={3} className="style-header">
                <Grid.Column floated='left'>
                    <img style={{ width: '245px', height: '86px' }} className="size-img-rotech" src={mySvg} alt="logo" />
                </Grid.Column>

                <Grid.Column floated='right' width={5} style={{ marginRight: 25 }}>
                    <Grid.Row style={{ marginTop: '8px' }} className="align-right-header">
                        <a aria-label='Facebook' href={process.env.REACT_APP_FACEBOOK_URL} target="_blank">
                            <img style={{ width: '63px', height: '28px' }} src={u68} alt="logo" />
                        </a>
                        <a aria-label='Twitter' href={process.env.REACT_APP_TWITTER_URL} target="_blank">
                            <img style={{ width: '29px', height: '28px' }} src={u69} alt="logo" />
                        </a>
                    </Grid.Row>

                    <Grid.Row style={{ marginTop: '15px' }} className="align-right-header responsive-row-globe-select">
                        <span className={loggedAdmin ? "letter-title-header responsive-row-globe-span-admin" : "letter-title-header responsive-row-globe-span"}>
                            {t('welcome_header')} {window.localStorage.getItem('userFirstName')}</span>
                        <Grid.Column style={{ float: 'right', marginTop: '-4px' }}>
                            <img className="globe" src={u66} alt="logo" />
                            <select aria-label="Change Language" defaultValue={lang} value={lang} className="select-header" onChange={changeLang}>
                                <option value="en">{t('english')}</option>
                                <option value="es">{t('spanish')}</option>
                            </select>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Fragment>
    );
};
