import React from "react";
import "./UnauthenticatedFooter.css";
import mySvgGpsIcon from "../../img/gps.svg";
import mySvgPhoneIcon from "../../img/phone.svg";
import mySvgMessageIcon from "../../img/message.svg";
import { useTranslation, Trans } from 'react-i18next';
import ReactGA from "react-ga"

function UnauthenticatedFooter() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <footer>
        <div className="location">
	  {t('contact-footer-a')}
	  <ReactGA.OutboundLink eventLabel="Unauthenticated Contact Us" to="https://www.rotech.com/contact-us" target="_blank">{t('contact-footer-b')}</ReactGA.OutboundLink>.
        </div>
      </footer>
      </div>
  );
}

export default UnauthenticatedFooter;
