import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Card, Divider, Modal } from 'semantic-ui-react';
import book from "../../img/u704.svg";
import cart from "../../img/u705.svg";
import shopRotech from "../../img/shopRotech-cart.png";
import shopRotechLogo from "../../img/shopRotech-logo.png";
import notebook from "../../img/u706.svg";
import arrowUp from "../../img/arrow-up.svg";
import arrowDown from "../../img/arrow-down.svg";
import "./Dashboard.css";
import ReactGA from "react-ga";
import InstamedSsoModal from './InstamedSsoModal';
import S3OrderLinkModal from './S3OrderLinkModal';

const splitLines = (text) => {
    if (!text) {
        return "";
    }
    return text.split('\n').map((item, i) => {
        return (<p key={i}>{item}</p>);
    });
}

const splitLinesAppointment = (text) => {
    if (!text) {
        return "";
    }
    return text.split('\n').map((item, i) => {
        return (<p key={i}>{item}</p>);
    });
}


const CardsPrincipal = ({ dataAppointments, servicingLocations, messages, alerts, classes, dataSecondary }) => {
    const { t } = useTranslation();
    const [pageMessages, setPageMessages] = useState(0);
    const [pageAlerts, setPageAlerts] = useState(0);
    const [pageApps, setPageApps] = useState(0);

    const changeMessages = (signo) => {
        switch (signo) {
            case '+':
                if ((messages.length - 1) != pageMessages) {
                    setPageMessages(p => p + 1);
                }
                break;
            case '-':
                if (pageMessages > 0) {
                    setPageMessages(p => p - 1);
                }
                break;
        }
    }

    const changeApps = (signo) => {
        switch (signo) {
            case '+':
                if ((dataAppointments.length - 1) != pageApps) {
                    setPageApps(p => p + 1);
                }
                break;
            case '-':
                if (pageApps > 0) {
                    setPageApps(p => p - 1);
                }
                break;
        }
    }

    const changePageAlerts = (signo) => {
        switch (signo) {
            case '+':
                if ((alerts.length - 1) != pageAlerts) {
                    setPageAlerts(p => p + 1);
                }
                break;
            case '-':
                if (pageAlerts > 0) {
                    setPageAlerts(p => p - 1);
                }
                break;
        }
    }
    //Orden:
    //SERVICING LOCATION
    //APPOINTMENTS
    //MESSAGES
    //ROTECH ALERTS
    return (<div className="ui cards" key="cardPrimary">
        <div className="card padding-height-card-primary card-shadow" key="SERVICING LOCATION" style={{ backgroundColor: '#3375B2' }}>
            <div className="content">
                <div className="header"><p className={classes.colorLetterWhite}>{t('dashboardServicingLocation')}</p></div>
                <Divider className="divider-white" />
                <div className="description">
                    <div className={classes.colorLetterWhite}>
                        {servicingLocations &&
                            splitLines(servicingLocations)
                        }
                        {(!servicingLocations || servicingLocations.trim().length === 0) &&
                            t('dashboard_serviceLocation_empty')
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className="card padding-height-card-primary card-shadow" key="APPOINTMENTS" style={{ backgroundColor: '#3375B2' }}>
            <div className="content">
                <div className="header"><p className={classes.colorLetterWhite}>{t('dashboardAppointments')}</p></div>
                <Divider className="divider-white" />
                <div className="description heightCardDescription">
                    <div className={classes.colorLetterWhite}>
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch">
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="stretch"
                                    className="card-inner-container"
                                >
                                    <Grid item>
                                        {dataAppointments &&
                                            <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                                {splitLinesAppointment(dataAppointments[pageApps])}</p>
                                        }
                                        {(dataAppointments && dataAppointments.length === 0) &&
                                            <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                                {t('dashboard_appointment_empty')}</p>
                                        }
                                    </Grid>
                                    {dataAppointments.length > 1 && (
                                        <>
                                            <Grid item className="style-grid-icons">
                                                <img onClick={() => changeApps('-')} src={arrowUp} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button' />
                                                <img onClick={() => changeApps('+')} src={arrowDown} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button style-icon down' />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                {dataAppointments.length > 1 && (
                                    <>
                                        <Grid item xs={12}>
                                            <strong className={classes.colorLetterWhite}>{pageApps + 1} of {dataAppointments.length}</strong>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                    </div>
                </div>
            </div>
        </div>

        <div className="card padding-height-card-primary card-shadow" key="MESSAGES" style={{ backgroundColor: '#3375B2' }}>
            <div className="content">
                <div className="header"><p className={classes.colorLetterWhite}>{t('dashboardMessages')}</p></div>
                <Divider className="divider-white" />
                <div className="description heightCardDescription">
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch">
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                className="card-inner-container"
                            >
                                <Grid item>
                                    {messages &&
                                        <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                            {messages[pageMessages]}</p>
                                    }
                                    {(messages && messages.length === 0) &&
                                        <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                            {t('dashboard_messages_empty')}</p>
                                    }

                                </Grid>
                                {messages.length > 1 && (
                                    <>
                                        <Grid item className="style-grid-icons">
                                            <img onClick={() => changeMessages('-')} src={arrowUp} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button' />
                                            <img onClick={() => changeMessages('+')} src={arrowDown} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button style-icon down' />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            {messages.length > 1 && (
                                <>
                                    <Grid item xs={12}>
                                        <strong className={classes.colorLetterWhite}>{pageMessages + 1} of {messages.length}</strong>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>

        <div className="card padding-height-card-primary card-shadow" key="ROTECH ALERTS" style={{ backgroundColor: '#3375B2' }}>
            <div className="content">
                <div className="header"><p className={classes.colorLetterWhite}>{t('dashboardAlerts')}</p></div>
                <Divider className="divider-white" />
                <div className="description">

                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch">
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="stretch"
                                className="card-inner-container"
                            >
                                <Grid item>
                                    {alerts &&
                                        <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                            {alerts[pageAlerts]}</p>
                                    }
                                    {(alerts && alerts.length === 0) &&
                                        <p className={classes.colorLetterWhite} style={{ paddingRight: 8 }}>
                                            {t('dashboard_alerts_empty')}</p>
                                    }
                                </Grid>
                                {alerts.length > 1 && (
                                    <>
                                        <Grid item className="style-grid-icons">
                                            <img onClick={() => changePageAlerts('-')} src={arrowUp} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button' />
                                            <img onClick={() => changePageAlerts('+')} src={arrowDown} alt="logo" width={20} style={{ cursor: 'pointer' }} className='icon-button style-icon down' />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            {alerts.length > 1 && (
                                <>
                                    <Grid item xs={12}>
                                        <strong className={classes.colorLetterWhite}>{pageAlerts + 1} of {alerts.length}</strong>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </div>

    );
}

const CardsInfo = ({ dataInfo, classes }) => {
    const { t } = useTranslation();
    const cardsInfo = dataInfo.map(({ title, content, titleImg, nameImg, link }) => {
        return <div key={title} className={nameImg === "u706skip" ? "background-color-white-card card card-shadow" : "background-color-gray-card card card-shadow"}>
            <div className="content">
                <div className="header"><p className={classes.colorLetterBlueBlack}>{t(title)}</p></div>
                <Divider className="divider-blue-black" />
                <div className="description heightCardInfoDescription">
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        className="heightTotal">
                        <Grid item className={nameImg === "u706skip" ? "negative-margin-dashboard subCardTitle" : "subCardTitle"}>
                            <p className={classes.colorLetterBlueBlack}>{t(content)}</p>
                        </Grid>
                        <Grid item className="margin-grid-title-card-info">
                            {titleImg === "shopRotech" &&
                                <>
        		                    <ReactGA.OutboundLink eventLabel={t(titleImg)} to={link} target="_blank" class="letter-title-img-dashboard">ShopRotech</ReactGA.OutboundLink>

                                </>
                            }
                            {titleImg == 'dashboard_cards_payment_title_img' &&
                                <>
                                    <InstamedSsoModal/>
                                </>
                            }
                            {titleImg == 'dashboard_cards_cpap_title_img' &&
                                <>
                                    <S3OrderLinkModal/>
                                </>
                            }
                            {titleImg == 'dashboard_cards_educational_materials_title_img' &&
                                <>
        		                    <ReactGA.OutboundLink eventLabel={t(titleImg)} to={link} target="_blank" class="letter-title-img-dashboard">{t(titleImg)}</ReactGA.OutboundLink>
                                </>
                            }
                        </Grid>
                        {nameImg === "shopRotech" &&
                            <>
                                <Grid item>
                                    <img src={shopRotech} alt="logo" width={60} style={{paddingBottom: '10px'}} />
				</Grid>
                            </>
                        }
                        <Grid item>
                            {nameImg === "u704" &&
                                <>
                                    <img src={book} alt="logo" width={60} />
                                </>
                            }

                            {nameImg === "u705" &&
                                <>
                                    <img src={cart} alt="logo" width={60} />
                                </>
                            }

                            {nameImg === "shopRotech" &&
                                <>
        		            <ReactGA.OutboundLink to={link} target="_blank" class="letter-title-img-dashboard">
                                        <img src={shopRotechLogo} height={24} alt="Shop Rotech Logo"/>
                                    </ReactGA.OutboundLink>
                                </>
                            }

                            {nameImg === "u706" &&
                                <>
                                    <img src={notebook} alt="logo" width={60} />
                                </>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    });

    return (<div className="ui cards" key="cardPrimary">
        {cardsInfo}
    </div>
    );
}

const useStyles = makeStyles({
    colorLetterWhite: {
        color: 'white'
    },
    colorLetterBlueBlack: {
        color: '#002140'
    },
});

export default function DashboardCards(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <Card.Group centered className="color-background-cards">
                <div className="ui four cards" style={{ marginTop: 0 }}>
                    <CardsPrincipal dataAppointments={props.dataAppointments}
                        servicingLocations={props.servicingLocations}
                        messages={props.messages} alerts={props.alerts}

                        dataPrimary={props.principalCards} dataSecondary={props.secondaryCards} classes={classes}></CardsPrincipal>
                </div>
            </Card.Group>

            <Card.Group centered>
                <div className="ui four cards">
                    <CardsInfo dataInfo={props.infoCards} classes={classes}></CardsInfo>
                </div>
            </Card.Group>
        </Fragment>
    );
}
