import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Dimmer, Grid, Loader, Radio } from 'semantic-ui-react';
import api from "../../api";
import "./UnauthenticatedAuthentication.css";
import Toast from "../common/Toast";


const UnauthenticatedAuthentication = (props) => {
    const { t } = useTranslation();

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            optionSelected: props.twoFactorSelected
        }
    });

    function HandleChange(e, { value }) {
        props.setTwoFactorSelected(value);
    }

    function actionButtonSubmit() {
        props.sendPin();
    }

    useEffect(() => {
        if (props.twoFactorOptions) {
            props.setTwoFactorSelected(props.twoFactorOptions[0]);
        }
    }, [])


    const formFields = props.twoFactorOptions.map((dataName) => {
        return <Form.Field>
            <Radio
                label={dataName}
                name={dataName}
                value={dataName}
                checked={props.twoFactorSelected === dataName}
                onChange={HandleChange}
            />
        </Form.Field>
    });


    return (
        <form className="form-anauthenticated-authentication" onSubmit={handleSubmit(actionButtonSubmit)}>

            {props.openToast &&
                <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid rows={5} className="grid-anauthenticated-authentication">
                <Grid.Row centered>
                    <h1 className="letter-title-authenticated">{t("authentication")}</h1>
                </Grid.Row>

                <Grid.Row>
                    <span className="letter-data-body-authenticated" style={{ lineHeight: 'normal' }}>{t("authenticationMessage")}</span>
                </Grid.Row>

                <Grid.Row className="center-checks-authenticated">
                    <Form>
                        {formFields}
                    </Form>
                </Grid.Row>

                <Grid.Row centered>
                    <button type="submit" className="button-circle" style={{ width: '40%', height: '47px', margin: 0 }}>{t("sendVerificationCode")}</button>
                    <Link to={'/home'} className="style-btn-cancel-authenticated" style={{ margin: '18px' }}>{t("cancel")}</Link >
                </Grid.Row>

                <Grid.Row centered>
                    <p className="letter-data-body-authenticated"><span>{t("registerCondition")}</span></p>
                </Grid.Row>

            </Grid >
        </form>
    );
}

export default UnauthenticatedAuthentication;
