import { createSlice } from '@reduxjs/toolkit'

const { actions, reducer } = createSlice({
    name: 'auth',
    initialState: {
        language: {
            selected: "en"
        },
        profile: undefined,
        loading: true,
        user: null,
        authenticated: false,
        token: undefined,
        language: 'en',
        location: '/dashboard',
        username: undefined,
        password: undefined,
        twoFactor: {
            options: [
                "317-xxx-xx34",
                "999-xxx-xx99",
                "JExxxxx@gxxx.com"
            ],
            selected: "317-xxx-xx34"
        }
    },
    reducers: {
        changeLanguage(state, { payload }) {
            state.language.selected = payload;
        },
        setLocation(state, { payload }) {
            state.location = payload;
        },
        setProfile(state, {payload}) {
            state.profile = payload;
        },
        setUsername(state, {payload}) {
            state.username = payload;
        },
        setPassword(state, {payload}) {
            state.password = payload;
        },
        logout(state){
            state.user = null;
            state.username = null;
            state.password = null;
        },
        setUnAuth(state) {
            state.authenticated = false;
            state.user = null;
            state.token = null;
        },
        setAuth(state, { payload }) {
            state.loading = false;
            state.authenticated = true;
            state.token = payload.token;
            state.admin = payload.admin;
            state.profile = payload.profile;
        },
    },
});

export const { setAuth, setLocation, changeSelectedOption, logout, setUsername, setPassword, setUnAuth, setProfile } = actions;

export default reducer;