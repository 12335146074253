import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Grid, Header, Dimmer, Loader } from 'semantic-ui-react';
import { setUnAuth } from "../../redux/auth";
import api from '../../api';

import "./Navbar.css";

export default function Navbar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loadingActive, setLoadingActive] = useState();

    const viewButtonDashboardSelected = () => {
        let url = window.location.href;
        if (url.includes('dashboard')) {
            return true;
        }
        else {
            return false;
        }
    }

    const viewButtonProfileSelected = () => {
        let url = window.location.href;
        if (url.includes('profile')) {
            return true;
        }
        else {
            return false;
        }
    }

    const viewButtonHistorySelected = () => {
        let url = window.location.href;
        if (url.includes('history')) {
            return true;
        }
        else {
            return false;
        }
    }

    function actionLogOut() {
        setLoadingActive(true);
        api.logout().then(() => {
            setLoadingActive(false);
            window.localStorage.removeItem("SessionToken");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("loggedIn");
            window.localStorage.removeItem("loggedInAdmin");
            window.localStorage.removeItem("userFirstName");
            dispatch(setUnAuth());
            history.replace("/home");
        }).catch((err) => {
            setLoadingActive(false);
            window.localStorage.removeItem("SessionToken");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("loggedIn");
            window.localStorage.removeItem("loggedInAdmin");
            window.localStorage.removeItem("userFirstName");
            dispatch(setUnAuth());
            history.replace("/home");
        });
    }

    return (
        <Header as='h2' className="color-no-margin-header margin-navbar" role="navigation" aria-label={t(' aria-label-navbar')}>
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader>{t('loggingOut')}</Loader>
                </Dimmer>
            }
            <Grid columns={4}>
                <Grid.Column className={viewButtonDashboardSelected()
                    ?
                    "row-selected-navbar width-dashboard-navbar"
                    :
                    "width-dashboard-navbar"}>
                    <Link role='heading'
                        aria-level={viewButtonDashboardSelected() ? '1' : '2'}
                        to={'/dashboard'}
                        className={viewButtonDashboardSelected()
                            ?
                            "button-selected-navbar position-button-navbar"
                            :
                            "font-button-navbar position-button-navbar"}>
                        {t('DASHBOARD')}</Link>
                </Grid.Column>

                <Grid.Column className={viewButtonProfileSelected()
                    ?
                    "row-selected-navbar width-profile-navbar"
                    :
                    "width-profile-navbar"}>
                    <Link role='heading'
                        aria-level={viewButtonProfileSelected() ? '1' : '2'}
                        to={'/profile'}
                        className={viewButtonProfileSelected()
                            ?
                            "button-selected-navbar position-button-navbar"
                            :
                            "font-button-navbar position-button-navbar"}>
                        {t('PROFILE')}</Link>
                </Grid.Column>

                {localStorage.getItem('language') == 'en' ?
                    <Grid.Column className={viewButtonHistorySelected()
                        ?
                        "row-selected-navbar width-service-history-navbar"
                        :
                        "width-service-history-navbar"}>
                        <Link role='heading'
                            aria-level={viewButtonHistorySelected() ? '1' : ''}
                            to={'/service-history'}
                            className={viewButtonHistorySelected()
                                ?
                                "button-selected-navbar position-button-navbar"
                                :
                                "font-button-navbar position-button-navbar"}>
                            {t('SERVICEHISTORY')}</Link>
                    </Grid.Column>
                    :
                    <Grid.Column className={viewButtonHistorySelected()
                        ?
                        "size-column-historial-servicio row-selected-navbar"
                        :
                        "size-column-historial-servicio"}>
                        <Link
                            to={'/service-history'}
                            className={viewButtonHistorySelected()
                                ?
                                "button-selected-navbar position-button-navbar"
                                :
                                "font-button-navbar position-button-navbar"}>
                            {t('SERVICEHISTORY')}</Link>
                    </Grid.Column>
                }

                <Grid.Column floated='right' className="width-sign-out-navbar text-aling-sign-out-navbar">
                    <a onClick={actionLogOut}
                        className="font-button-navbar position-button-navbar">
                        {t('SIGNOUT')}</a>
                </Grid.Column>
            </Grid>
        </Header>
    );
}