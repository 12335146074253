import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL
const apiKey = process.env.REACT_APP_API_KEY

const instance = axios.create({
    baseURL: apiUrl
});

axios.defaults.withCredentials = true;
// Alter defaults after instance has been created
instance.defaults.headers.common['x-api-key'] = apiKey;
instance.defaults.headers.common['SessionToken'] = '-';
if(window.localStorage.getItem('loggedIn')){
    instance.defaults.headers.common['SessionToken'] = window.localStorage.getItem('SessionToken');
}
if(window.localStorage.getItem('language')){
    instance.defaults.headers.common['Accept-Language'] = window.localStorage.getItem('language');
} else {
    instance.defaults.headers.common['Accept-Language'] = 'en';
}

instance.defaults.withCredentials = false;

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error && ((error.response && (error.response.status === 401||error.response.status === 403 )) ) && window.localStorage.getItem('loggedIn')){
        instance.post("/logout");
        let url = "/signin?sessionExpired=true";
        if(window.localStorage.getItem("loggedInAdmin")){
            url = "/admin/signin?sessionExpired=true"
        }
        window.localStorage.removeItem('loggedIn');
        window.localStorage.removeItem('loggedInAdmin');
        instance.defaults.headers.common['SessionToken'] = '-';
        window.localStorage.removeItem('SessionToken');
        window.location.href = url;
    } else if (error.message === 'Network Error') {
        console.log('This is a network error');
    }
    return Promise.reject(error);
});

export default {

    urlRedirectAdmin() {
        return process.env.REACT_APP_ADMIN_LOGIN_URL;
    },

    urlRedirectAdminLogout() {
        return process.env.REACT_APP_ADMIN_LOGOUT_URL;
    },

    postSignInAdmin(saml) {
        return instance.post('admin/saml', {
            "SAMLToken": saml
        });
    },

    postCheckValidAdmin() {
        return instance.post('admin/saml', {
            "SAMLToken": 'validate'
        }, {
            headers: {
                'SessionToken': window.localStorage.getItem('SessionToken')
            }
        });
    },

    test() {
    },

    userContact() {
        return instance.get("/users/contact", instance.defaults.headers.common['SessionToken']);
    },

    validationPIN(pin) {
        return instance.get("validation/pin", pin);
    },

    sendVerification(contact) {
        instance.get("/patient/send-verification", contact)
    },

    surnamePatientGet() {
        instance.get("/patient/surname", instance.defaults.headers.common['SessionToken'])
    },

    passwordPatientGet() {
        instance.get("/patient/surname", instance.defaults.headers.common['SessionToken'])
    },

    setSessionToken(token) {
        instance.defaults.headers.common['SessionToken'] = token;
    },

    setLanguage(lang) {
        instance.defaults.headers.common['Accept-Language'] = lang;
    },

    adminLoginPost(params, body, additionalParams) {
        instance.post("/admin/login", body);
    },
    auditlogCreatePost(params, body, additionalParams) {
        instance.post("/auditlog/create", body);
    },

    auditlogGetGet(body) {
        return instance.post("/auditlog/get", body);
    },

    documentGetGet(body) {
        return instance.post("/document/get", body);
    },

    documentUploadGet(body) {
        return instance.post("/document/upload", body);
    },

    logout() {
        return instance.post("/logout");
    },

    messageSendPost(params, body, additionalParams) {
        instance.post("/message/send", body);
    },

    patientGetGet(body) {
        return instance.post("/patient/get", body);
    },

    patientUpdatePost(body) {
        return instance.post("/patient/update", body);
    },

    patientValidatePost(body) {
        return instance.post("/patient/validate", body);
    },

    serviceGetGet(body) {
        return instance.post("/service/get", body);
    },

    userCreatePost(body) {
        return instance.post("/user/create", body);
    },

    userGetGet(body) {
        return instance.post("/user/get", body);
    },

    userLoginPost(body) {
        return instance.post("/user/login", body);
    },

    userUpdatePost(body) {
        return instance.post("/user/update", body);
    },

    resetPassword(body) {
        return instance.post("/user/update", body)
    },

    checkWindows() {
        return instance.post("/windows/check", {})
    },
    ssoInstamedUrl() {
        return instance.get("/sso/instamed", {})
    },
    orderLinkUrl() {
        return instance.get("/order-link", {})
    }
}
