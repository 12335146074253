import { configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import ui from './ui';
import createAccount from './createAccount';

export default configureStore({
    reducer: {
        auth,
        ui,
        createAccount
    },
});