import React from "react";
import { Fragment } from "react";
import "./UnauthenticatedHeader.css";
import mySvg from "../../img/PatientPortalLogo.svg";
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

const UnauthenticatedHeader = ({ admin }) => {
    const { t } = useTranslation();

    return (
        <Grid stackable columns={2} className="unauthenticatedHeader">
            <Grid.Column className="logo size-column-title-unauthenticated-header">
                <img src={mySvg} alt="logo" />
            </Grid.Column>
            <Grid.Column className="text-aling-welcome-header-unauthenticated size-column-title-unauthenticated-header" floated='right'>
                <h1 className="home-title">{!admin ? t('welcome') : t('welcome_admin')}</h1>
            </Grid.Column>
        </Grid>
    );
};

export default UnauthenticatedHeader;
