import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CreateAccountPassword.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import api from "../../api";
import Toast from "../common/Toast";
import ReactGA from 'react-ga';

const CreateAccountPassword = (props) => {
    const { t } = useTranslation();
    const [errorPass, setErrorPass] = useState(false);
    const [errorPassRequeriment, setErrorPassRequeriment] = useState(false);
    const [showUsernameComplexity, setShowUsernameComplexity] = useState(false);
    const [showPasswordComplexity, setShowPasswordComplexity] = useState(false);

    const { register, setValue, handleSubmit, errors, getValues, setError, clearError } = useForm({
        defaultValues: {
            reEnterPassword: undefined,
            enterPassword: undefined,
            enterUserId: undefined
        }
    });

    function validationPassword(pass, reEnterPass) {
        var request = true;
        if (pass.length < 8) {
            request = false;
        }

        var hasNumber = /\d/;
        if (!hasNumber.test(pass)) {
            request = false;
        }

        var hasUppercase = /[A-Z]|[\u0080-\u024F]/;
        if (!hasUppercase.test(pass)) {
            request = false;
        }

        return request;
    }

    const userNamePatientValidation = (value) => {
        return value.indexOf(props.patientId) === -1;
    }
    
    const usernameValidation = (value) => {
        let hasNumber = /\d/.test(value);
        let hasLetter = /[a-z]|[A-Z]|[\u0080-\u024F]/.test(value);
        return value.length > 5 && hasLetter && hasNumber;
    }

    function actionButtonSubmit() {
        let values = getValues();

        let passwordValidationRequeriment = validationPassword(values.enterPassword, values.reEnterPassword);

        let passwordDoNotMatch = values.enterPassword != values.reEnterPassword ? true : false;

        if (passwordDoNotMatch) {
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
        }

        if (!passwordValidationRequeriment) {
            setErrorPassRequeriment(true);
        }
        else {
            setErrorPassRequeriment(false);
        }

        if (passwordValidationRequeriment && !passwordDoNotMatch) {
            let body = {
                Username : values.enterUserId,
                Password : values.enterPassword,
                PatientId: props.patientId
            }
            props.setLoadingActive(true);
            api.userCreatePost(body)
            .then((response) => {
                props.setLoadingActive(false);
                if(response.data.code=='Error'){
                    ReactGA.event({category: 'Registration', action: 'Failed Registration', label: 'Failed Account Creation'});
                    props.setMessageError(response.data.message);
                    props.setOpenToast(true);
                } else {
                    api.setSessionToken(response.data.SessionToken);
                    props.nextPageRegister();    
                    ReactGA.event({category: 'Registration', action: 'Successful Registration', label: 'Success Account Creation'});
                }
                
            }).catch((err) => {
                props.setLoadingActive(false);
                ReactGA.event({category: 'Registration', action: 'Failed Registration', label: 'Failed Account Creation'});
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
         
        }
    }

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            {props.openToast &&
                <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid columns={1} rows={7} className="body-grid-create-account-password">
                <Grid.Row centered>
                    <span className="letter-title-create-account-password">{t("titleRegister")}</span>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-create-account-password">
                        {t("enterUserId")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <input onChange={e => {
                        const value = e.target.value
                        if (value.indexOf(props.patientId) === -1) return clearError("enterUserId")
                        setError("enterUserId", "username")
                        }} 
                        ref={register({required:true,
                            validate: {patientId : value => userNamePatientValidation(value), username : value => usernameValidation(value)
                        }})} type="text" id="enterUserId" name="enterUserId" className="input-create-account-password" />
                </Grid.Row>

	        <Grid.Column className="complexity-column">
	        <Grid.Row className="complexity-message padding-row-requeriment">
	            <span className="">{t('username-complexity-short')} </span>
	            { showUsernameComplexity ? 
	                <a href="#" onClick={()=>setShowUsernameComplexity(false)} className="">{t('hide')}</a> :
	                <a href="#" onClick={()=>setShowUsernameComplexity(true)} className="">{t('learn-more')}</a> 
	            }
	        </Grid.Row>
	        </Grid.Column>

	        { showUsernameComplexity ? 
                <Grid.Column>
                    <Grid.Row className="padding-row-requeriment">
                        <span className="letter-requeriment-title-create-account-password">{t("usernameCriteria0")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("usernameCriteria1")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("usernameCriteria2")}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("usernameCriteria3")}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("usernameCriteria4")}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("usernameCriteria5")}</span>
                    </Grid.Row>

                </Grid.Column> : null
		}

                {errors.enterUserId &&
                    <>
                        <span className="letter-error-create-account-password">
                            {errors.enterUserId.type == 'required' ? t('requiredField') : '' }
                            {errors.enterUserId.type == 'username' ? t('usernameInvalid') : '' }
                            {errors.enterUserId.type == 'patientId' ? t('usernamePatientIdInvalid') : '' }
                            <br/>
                            {t("pleasePickDifferentUserID")}
                        </span>
                    </>
                }

                <Grid.Row>
                    <label className="letter-body-create-account-password">
                        {t("enterNewPassword")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <input ref={register({ required: true })} type="password" id="enterPassword" name="enterPassword" className="input-create-account-password" />
                </Grid.Row>
	        <Grid.Column className="complexity-column">
	        <Grid.Row className="complexity-message padding-row-requeriment">
	            <span className="">{t('password-complexity-short')} </span>
	            { showPasswordComplexity ? 
	                <a href="#" onClick={()=>setShowPasswordComplexity(false)} className="">{t('hide')}</a> :
	                <a href="#" onClick={()=>setShowPasswordComplexity(true)} className="">{t('learn-more')}</a> 
	            }
	        </Grid.Row>
	        </Grid.Column>

	        { showPasswordComplexity ? 
                <Grid.Column>
                    <Grid.Row className="padding-row-requeriment">
                        <span className="letter-requeriment-title-create-account-password">{t("passwordCriteria0")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("passwordCriteria1")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("passwordCriteria2")}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="letter-requeriment-create-account-password">{t("passwordCriteria3")}</span>
                    </Grid.Row>
                </Grid.Column> : null
		}


                <Grid.Row>
                    <label className="letter-body-create-account-password">
                        {t("reEnterNewPassword")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <input ref={register({ required: true })} type="password" id="reEnterPassword" name="reEnterPassword" className="input-create-account-password" />
                </Grid.Row>
                {errorPass && !errors.enterPassword && !errors.reEnterPassword &&
                    <>
                        <span className="letter-error-create-account-password">{t("passwordsDoNotMatch")}</span>
                    </>
                }

                {errorPassRequeriment && !errorPass && !errors.enterPassword && !errors.reEnterPassword &&
                    <>
                        <span style={{ paddingRight: 33 }} className="letter-error-create-account-password">{t("passwordDoesNotMeetTheNecessaryRequirements")}</span>
                    </>
                }

                {errors.enterPassword &&
                    <>
                        <span style={{ marginBottom: 13 }} className="letter-error-create-account-password">{t("passwordNotEntered")}</span>
                    </>
                }
                {errors.reEnterPassword &&
                    <>
                        <span className="letter-error-create-account-password">{t("passwordConfirmationNotEntered")}</span>
                    </>
                }


                <Grid.Row centered>
                    <button type="submit" className="button-circle-create-account-password">{t("submit")}</button>
                    <Link className="letter-cancel-create-account-password" to={'/home'}>
                        {t("cancel")}
                    </Link>
                </Grid.Row>

                <Grid.Row centered style={{ paddingBottom: '20px' }}>
                    <span className="letter-black-create-account-password">Do you have an account?</span>
                    <Link className="letter-redirect-login-create-account-password" to={'/signin'}>Login</Link>
                </Grid.Row>
            </Grid>
        </form>
    );
};

export default CreateAccountPassword;
