import React from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';
import MaskedInput from 'react-maskedinput'

import "./Profile.css";

export const ProfileEditPatientProfile = (props) => {
    const { t } = useTranslation();
    const infoRegister = props.register;
    const setValue = props.setValue;
    const watchYes = props.watch('emailAddressPatient');
    const watchPhone = props.watch('phoneNumberPatient');
    const watchMobile = props.watch('mobilePatient');
    const emailTypes = ["Guarantor","Facility","Social Worker","Care Giver", "Family-Other", "Friend", "Employer","Other","Legal","Healthcare Power of Attorney","Spouse","Daughter","Son"];

    return (
        <Grid rows={3} id="two-column-patient-profile">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('firstName')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('firstName')} id="firstName" name="firstName" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.firstName ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('lastName')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('lastName')} id="lastNamePatient" name="lastNamePatient" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.lastNamePatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phoneNumber')} 
                            {!watchMobile ? <Icon className="mandatoryField" name='asterisk' />:null}
                            </span>
                            <p>
                                <input aria-label={t('phoneNumber')} id="phoneNumberPatient" name="phoneNumberPatient" type="hidden" ref={infoRegister({ required: !watchMobile, pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/  })} />
                                <MaskedInput aria-label={t('phoneNumber')} mask="(111) 111-1111" value={props.dataPatient.phoneNumber} onChange={(e)=>{setValue('phoneNumberPatient', e.target.value);}}  style={{ width: '100%' }} />
                            </p>
                            {props.errors.phoneNumberPatient ? <p className="mandatoryField">
                                {props.errors.phoneNumberPatient.type == 'required' ? t('requiredField') : '' }
                                {props.errors.phoneNumberPatient.type == 'pattern' ? t('phoneRequired') : '' }
                            </p>:null}

                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('mobile')} 
                            {!watchPhone ? <Icon className="mandatoryField" name='asterisk' />:null}
                            </span>
                            <p>
                                <input aria-label={t('mobile')} id="mobilePatient" name="mobilePatient" type="hidden" ref={infoRegister({ required: !watchPhone, pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/  })} />
                                <MaskedInput aria-label={t('mobile')} mask="(111) 111-1111" value={props.dataPatient.mobile} onChange={(e)=>{setValue('mobilePatient', e.target.value);}}  style={{ width: '100%' }} />    
                            </p>
                            {props.errors.mobilePatient ? <p className="mandatoryField">
                                {props.errors.mobilePatient.type == 'required' ? t('requiredField') : '' }
                                {props.errors.mobilePatient.type == 'pattern' ? t('phoneRequired') : '' }
                            </p>:null}
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5} className="profileGridAddress">
                        <Grid.Column width={8} className="size-column-profile">
                            <span className="font-title-body">{t('deliveryAddress')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('deliveryAddress')} id="addressPatient" name="addressPatient" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.addressPatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('city')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('city')} id="cityPatient" name="cityPatient" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.cityPatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}

                        </Grid.Column>

                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('state')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><select aria-label={t('state')} id="statePatient" name="statePatient" style={{ width: '100%', height: '22px' }} ref={infoRegister({ required: true })}>
                                <option value=""></option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AZ">AZ</option>
                                <option value="AR">AR</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="IA">IA</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="ME">ME</option>
                                <option value="MD">MD</option>
                                <option value="MA">MA</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MT">MT</option>
                                <option value="NE">NE</option>
                                <option value="NV">NV</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NY">NY</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">&nbsp;TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WV">WV</option>
                                <option value="WI">WI</option>
                                <option value="WY">WY</option>
                                <option value="DC">DC</option>
                            </select></p>
                            {props.errors.statePatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>
                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('zipCode')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('zipCode')} id="zipCodePatient" name="zipCodePatient" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.zipCodePatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p><input aria-label={t('emailAddress')} type="email" id="emailAddressPatient" name="emailAddressPatient" style={{ width: '100%' }} ref={infoRegister({ required: false, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} /></p>
                            {props.errors.emailAddressPatient ? <p className="mandatoryField">
                                {props.errors.emailAddressPatient.type == 'required' ? t('requiredField') : '' }
                                {props.errors.emailAddressPatient.type == 'pattern' ? t('emailRequired') : '' }
                            </p>:null}
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}
                            {watchYes ? <Icon className="mandatoryField" name='asterisk' />:null}
                            </span>
                            <p><select aria-label={t('emailType')} id="emailTypePatient" name="emailTypePatient" style={{ width: '100%', height: '22px' }} ref={infoRegister({ required: watchYes })}>
                                <option value=""></option>
                                <option value="Personal">Personal</option>
                                <option value="Work">Work</option>
                            </select></p>
                            {props.errors.emailTypePatient ? <p className="mandatoryField">{t('requiredField')}</p>:null}
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    )

}


export default function ProfilePatientProfile(props) {
    const { t } = useTranslation();
    return (
        <Grid rows={3} id="two-column-patient-profile">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('firstName')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.firstName ? "" : props.dataPatient.firstName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('lastName')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.lastName ? "" : props.dataPatient.lastName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phoneNumber')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.phoneNumber? "" : props.dataPatient.phoneNumber}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('mobile')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.mobile ? "" : props.dataPatient.mobile}</p>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={8} className="size-column-profile">
                            <span className="font-title-body">{t('deliveryAddress')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.address ? "" : props.dataPatient.address}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('city')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.city ? "" : props.dataPatient.city}</p>
                        </Grid.Column>

                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('state')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.state ? "" : props.dataPatient.state}</p>
                        </Grid.Column>

                        <Grid.Column width={2} className="size-column-profile">
                            <span className="font-title-body">{t('zipCode')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.zipCode ? "" : props.dataPatient.zipCode}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile column-responsive-email-address-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.emailId ? "" : props.dataPatient.emailId}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}</span>
                            <p className="data-body">{!props.dataPatient || !props.dataPatient.emailType ? "" : props.dataPatient.emailType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}
