import React, { useState } from "react";
import { Dimmer, Loader } from 'semantic-ui-react';
import ForgotPassword from "./ForgotPassword";
import UnauthenticatedAuthentication from "./UnauthenticatedAuthentication";
import UnauthenticatedAuthenticationTwo from "./UnauthenticatedAuthenticationTwo";
import ResetPassword from "./ResetPassword";
import api from "../../api";

const ComponentsForgotPassword = (props) => {
    const [page, setPage] = useState(1);
    const [username, setUsername] = useState('');
    const nextPageForgotPassword = () => setPage(p => p + 1);
    const lastPageForgotPassword = () => setPage(p => p - 1);
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [loadingActive, setLoadingActive] = useState();
    const [messageError, setMessageError] = useState();

    const sendPin = () => {
        let bodyContact = {
            Username: username,
            ChallengeResponse: {
                ContactOption: twoFactorSelected,
                Type: '2FA'
            }
        }
        setLoadingActive(true);
        api.userUpdatePost(bodyContact).then((response) => {
            setLoadingActive(false);
            nextPageForgotPassword();
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const validatePin = (values) => {
        let body = {
            "Username": username,
            "ChallengeResponse": {
                "Type": "2FA",
                "MfaPin": values.authenticationCode
            }
        }
        setLoadingActive(true);
        api.userUpdatePost(body).then((response) => {
            setLoadingActive(false);
            nextPageForgotPassword();
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest){
                    lastPageForgotPassword();
                }
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    return (
        <>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {page === 1 &&
                <ForgotPassword
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    setUsername={setUsername} lastPageForgotPassword={lastPageForgotPassword}
                    nextPageForgotPassword={nextPageForgotPassword} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/forgotPassword"} />}

            {page === 2 &&
                <UnauthenticatedAuthentication username={username} lastPage={lastPageForgotPassword}
                    sendPin={sendPin} twoFactorSelected={twoFactorSelected}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/unauthenticated-authentication-password"} />}

            {page === 3 &&
                <UnauthenticatedAuthenticationTwo username={username} lastPage={lastPageForgotPassword}
                    validatePin={validatePin} twoFactorSelected={twoFactorSelected}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/unauthenticated-authenticationTwo-password"} />}

            {page === 4 &&
                <ResetPassword
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    username={username} setLoadingActive={setLoadingActive} lastPageForgotPassword={lastPageForgotPassword}
                    nextPageForgotPassword={nextPageForgotPassword} />}
        </>
    );
};

export default ComponentsForgotPassword;