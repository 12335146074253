import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { DateInput } from 'semantic-ui-calendar-react';
import { Divider, Grid, Dimmer, Loader } from 'semantic-ui-react';
import { useLocation } from "react-router-dom";
import "./AuditLog.css";
import AuditLogList from './AuditLogList';
import api from '../../api';
import Toast from "../common/Toast";
import moment from 'moment';

export default function AuditLogHome(props) {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState("Type");
    const [sortDirection, setSortDirection] = useState("asc");
    const [loadingActive, setLoadingActive] = useState(false);
    const [dataAuditLog, setDataAuditLog] = useState();
    const [messageError, setMessageError] = useState();
    const [pagination, setPagination] = useState({ TotalPages: 0, ItemsPerPage: 3, PageNumber: 1 });
    const [eventTypes, setEventTypes] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    let query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            patientIdFilter: undefined,
            typeFilter: undefined,
            userFilter: undefined,
            affectedUserFilter: undefined
        }
    });

    useEffect(()=>{
        if(query.get('patientId')){
            setValue('patientIdFilter', query.get('patientId'));
        }
        loadDependencies();
    },[]);

    function actionButtonSubmit(sub) {
        filterList();
    }

    const handleDateChange = (event, { name, value }) => {
        if (name === 'dateToFilter') {
            setDateTo(value);
        }

        if (name === 'dateFromFilter') {
            setDateFrom(value);
        }
    };

    useEffect(() => {
        if (!firstLoad) {
            if (page === 1) {
                setLoadingActive(true);
                filterList();
            }
            else {
                setPage(1);
            }
        }
        else {
            setFirstLoad(false);
        }
    }, [sortColumn, sortDirection]);

    useEffect(() => {
        setLoadingActive(true);
        filterList();
    }, [page]);

    const loadDependencies = () => {
        let payload = { FilterList: true };
        api.auditlogGetGet(payload).then((resp) => {
            if(resp.data.EventTypes){
                setEventTypes(resp.data.EventTypes);
            }
        }).catch((err) => {
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const filterList = () => {
        setLoadingActive(true);
        let values = getValues();
        let hasValues = false;
        let filter = {
        }
        if (dateFrom) {
            hasValues = true;
            filter.FromDate = moment(dateFrom, ['MM-DD-YYYY', 'DD-MM-YYYY']).toDate();
        }
        if (dateTo) {
            hasValues = true;
	    filter.ToDate = moment(dateTo, ['MM-DD-YYYY', 'DD-MM-YYYY']).add(1, 'd').subtract(1, 's').toDate();
        }

        if (values.patientIdFilter) {
            hasValues = true;
            filter.PatientId = values.patientIdFilter;
        }
        if (values.typeFilter|| values.typeFilterF) {
            hasValues = true;
            filter.Type = values.typeFilter ? values.typeFilter:values.typeFilterF;
        }
        if (values.userFilter) {
            hasValues = true;
            filter.Username = values.userFilter;
        }
        if (values.affectedUserFilter||values.affectedUserFilterF) {
            hasValues = true;
            filter.AffectedUsername = values.affectedUserFilter ? values.affectedUserFilter : values.affectedUserFilterF;
        }

        if (!hasValues) {
            setLoadingActive(false);
            setPagination({ PageNumber: 1, TotalPages: 1 });
            setDataAuditLog([]);
            return;
        }
        let payload = filter;
        payload.Pagination = { PageNumber: page, SortColumn: sortColumn, SortDirection: sortDirection };
        api.auditlogGetGet(payload).then((resp) => {
            if (!resp.data.Pagination) {
                resp.data.Pagination = { TotalPages: 1, TotalItems: 1, ItemsPerPage: 1, PageNumber: 1, StartItem: 1, EndItem: 1 };
            }
            setLoadingActive(false);
            setDataAuditLog(resp.data.AuditLogEntries);
            setPagination(resp.data.Pagination);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });;
    }

    const clearFilters = () => {
        setValue("userFilter", undefined);
        setValue("roleFilter", undefined);
        setValue("patientIdFilter", undefined);
        setValue("typeFilter", undefined);
        setValue("typeFilterF", undefined);
        setValue("status", undefined);
        setValue("affectedUserFilter", undefined);
        setValue("affectedUserFilterF", undefined);
        setDateFrom(undefined);
        setDateTo(undefined);

        // filterList()
        setLoadingActive(false);
        setPagination({ PageNumber: 1, TotalPages: 1 });
        setDataAuditLog([]);
        return;
    }

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid stackable rows={3} centered>
                <Grid.Row className="fix-padding-row filters">
                    <Grid stackable columns={4}>
                        <Grid.Row className="fix-padding-row">
                            <Grid.Column className="full-width-resposive">
                                <span className="letter-name-filter">{t('patientId')}:</span>
                                <input className="full-width-resposive" name="patientIdFilter" id="patientIdFilter" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive view-filter-audit-log-down">
                                <span className="letter-name-filter">{t('type')}:</span>
                                <select name="typeFilter" id="typeFilter" className="margin-width-type full-width-resposive" ref={register({ required: false })}>
                                    <option value=""></option>
                                    {eventTypes.map((et)=>{
                                        return (<option key={et} value={et}>{et}</option>);
                                    })}                                    
                                </select>
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive">
                                <span className="letter-name-filter">{t('user')}:</span>
                                <input name="userFilter" id="userFilter" className="margin-user-audit-log full-width-resposive" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive view-filter-audit-log-down">
                                <span className="letter-name-filter">{t('affectedUser')}:</span>
                                <input name="affectedUserFilter" id="affectedUserFilter" className="full-width-resposive" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column className="responsive-column-date-audit-log full-width-resposive">
                                <span className="letter-name-filter">{t('dateFrom')}:</span>
                                <DateInput
                                    dateFormat="MM-DD-YYYY"
                                    closeOnMouseLeave={false}
                                    closable={true}
                                    name="dateFromFilter"
                                    id="dateFromFilter"
                                    placeholder={t('dateFrom')}
                                    iconPosition="left"
                                    value={dateFrom}
                                    className="full-width-resposive date-filter-audit-log date-filter"
                                    animation="none"
                                    onChange={handleDateChange}
                                    ref={register({ required: false })}
                                />
                            </Grid.Column>

                            <Grid.Column className="responsive-column-date-audit-log full-width-resposive view-filter-audit-log-down">
                                <b className="letter-name-filter">{t('dateTo')}:</b>
                                <DateInput
                                    dateFormat="MM-DD-YYYY"
                                    closable={true}
                                    name="dateToFilter"
                                    id="dateToFilter"
                                    placeholder={t('dateTo')}
                                    iconPosition="left"
                                    value={dateTo}
                                    className="full-width-resposive date-filter-audit-log date-filter"
                                    animation="none"
                                    onChange={handleDateChange}
                                    ref={register({ required: false })}
                                />
                            </Grid.Column>

                            <Grid.Column className="center-btn-audit-log view-filter-audit-log-up" style={{ alignSelf: 'flex-end' }}>
                                <button type="submit" className="button-circle size-button-filters size-btn-audit-log" name="filter" id="filter">{t('filter')}</button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>

                <Grid.Row className="fix-padding-row filters">
                    <Grid stackable columns={4}>
                        <Grid.Row className="fix-padding-row">
                            <Grid.Column className="full-width-resposive view-filter-audit-log-up">
                                <span className="letter-name-filter">{t('type')}:</span>
                                <select name="typeFilterF" id="typeFilterF" className="margin-width-type full-width-resposive" ref={register({ required: false })}>
                                    <option value=""></option>
                                    {eventTypes.map((et)=>{
                                        return (<option key={et} value={et}>{et}</option>);
                                    })}
                                </select>
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive view-filter-audit-log-up">
                                <span className="letter-name-filter">{t('affectedUser')}:</span>
                                <input name="affectedUserFilterF" id="affectedUserFilterF" className="full-width-resposive" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column className="responsive-column-date-audit-log full-width-resposive view-filter-audit-log-up">
                                <b className="letter-name-filter">{t('dateTo')}:</b>
                                <DateInput
                                    dateFormat="MM-DD-YYYY"
                                    closable={true}
                                    name="dateToFilter"
                                    id="dateToFilter"
                                    placeholder={t('dateTo')}
                                    iconPosition="left"
                                    value={dateTo}
                                    className="full-width-resposive date-filter-audit-log date-filter"
                                    animation="none"
                                    onChange={handleDateChange}
                                    ref={register({ required: false })}
                                />
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive center-btn-audit-log view-filter-audit-log-down" style={{ alignSelf: 'flex-end' }}>
                                <button type="submit" className="button-circle size-button-filters size-btn-audit-log" name="filter" id="filter">{t('filter')}</button>
                            </Grid.Column>

                            <Grid.Column className="full-width-resposive center-btn-audit-log" style={{ alignSelf: 'flex-end' }}>
                                <button type="button" onClick={clearFilters} className="button-circle size-button-filters size-btn-audit-log" name="clearFilter" id="clearFilter">{t('clearFilter')}</button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row >
                <Divider section />
                <Grid.Row className="fix-padding-row">
                    {dataAuditLog &&
                        <AuditLogList pagination={pagination} setPage={setPage} dataAuditLog={dataAuditLog}
                            sortColumn={sortColumn} setSortColumn={setSortColumn} sortDirection={sortDirection} setSortDirection={setSortDirection} />
                    }
                </Grid.Row>
            </Grid>
        </form>
    );
}
