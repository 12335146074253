import React from "react";
import { Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import "./FooterAuthenticated.css";
import { Fragment } from "react";
import ReactGA from "react-ga";

export default function FooterAuthenticated() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Grid columns='equal' className="line-height-footer margin-top-footer">
                <Grid.Row color='black' textAlign='center' computer={13}>
                    <Grid.Column>
                        <Segment color='black' inverted>
	    		    <ReactGA.OutboundLink eventLabel={t('privacyPolicy')} to={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" class="font-button-footer">{t('privacyPolicy')}</ReactGA.OutboundLink>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='black' inverted>
	    		    <ReactGA.OutboundLink eventLabel={t('termsOfUse')} to={process.env.REACT_APP_TERMS_OF_USE_URL} target="_blank" class="font-button-footer">{t('termsOfUse')}</ReactGA.OutboundLink>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='black' inverted>
	    		    <ReactGA.OutboundLink eventLabel={t('HIPAA')} to={process.env.REACT_APP_HIPAA_URL} target="_blank" class="font-button-footer">{t('HIPAA')}</ReactGA.OutboundLink>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='black' inverted>
	    		    <ReactGA.OutboundLink eventLabel={t('contactUs')} to={process.env.REACT_APP_CONTACT_US_URL} target="_blank" class="font-button-footer">{t('contactUs')}</ReactGA.OutboundLink>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className="style-text-footer">
                {t('copyright')}
                </div>
        </Fragment>
    );
}
