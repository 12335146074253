import axios from 'axios';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import "./AuthenticatedAuthentication.css";
import api from "../../api";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function AuthenticatedAuthentication(props) {
    const { t } = useTranslation();
    const [loadingActive, setLoadingActive] = useState();

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            authenticationCode: ""
        }
    });

    function actionButtonSubmit() {
        switch (props.type) {
            case 'upload':
                if (props.uploadCard) {
                    validatePinUpload();
                } else {
                    validatePinManualUpload();
                }
                break;
            case 'profileEdit':
                validatePinProfileEdit();
                break;
            case 'changePassword':
                props.validatePin(getValues());
                break;
        }
    }

    function fileUpload(file, urlFileUpload) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
		'x-amz-acl': 'bucket-owner-full-control'
            }
        }
        let instance = axios.create();
        instance.defaults.withCredentials = false;
        return instance.put(urlFileUpload, file, config);
    }

    const validatePinUpload = () => {
        let values = getValues();
        let body = {
            InsuranceTier: props.primary ? "Primary" : "Secondary",
            ChallengeResponse: {
                "Type": "2FA",
                "MfaPin": values.authenticationCode
            }
        }
        setLoadingActive(true);

        api.documentUploadGet(body).then((response) => {
            let coord = response.data.UploadCoordinates[0];
            let file = props.fileFront;
            if(coord.CardFace == 'Back'){
                file = props.fileBack;
            }
            props.setLoadingMessage(t('uploading_' + coord.CardFace));
            fileUpload(file, coord.URL).then((resp) => {
                let coord = response.data.UploadCoordinates[1];
                let file = props.fileFront;
                if(coord.CardFace == 'Back'){
                    file = props.fileBack;
                }
                props.setLoadingMessage(t('uploading_' + coord.CardFace));
                fileUpload(file, coord.URL).then((resp) => {
                    props.setLoadingMessage('Loading');
                    props.setPageComponentsUpload('ProfilePasswordChange');
                }).catch((err) => {
                    setLoadingActive(false);
                });    

                }).catch((err) => {
                    setLoadingActive(false);
                })
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest||err.response.data.message=="Too many failed attempts. A new token must be issued."){
                    props.setPageComponentsUpload('AuthenticatedAuthentication');
                }
                props.setMessageError(err.response.data.message)
            }
            else {
                props.setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const validatePinManualUpload = () => {
        let values = getValues();
        let body = props.bodyManualUpload;
        body.ChallengeResponse = {
            "Type": "2FA",
            "MfaPin": values.authenticationCode
        }
        setLoadingActive(true);
        api.patientUpdatePost(body).then((response) => {
            props.setPageComponentsUpload('ProfilePasswordChange');
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest||err.response.data.message=="Too many failed attempts. A new token must be issued."){
                    props.setPageComponentsUpload('AuthenticatedAuthentication');
                }
                props.setMessageError(err.response.data.message)
            }
            else {
                props.setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const validatePinProfileEdit = () => {
        let values = getValues();
        let body = props.bodyProfileEdit;
        body.ChallengeResponse = {
            "Type": "2FA",
            "MfaPin": values.authenticationCode
        }
        setLoadingActive(true);
        api.patientUpdatePost(body).then((response) => {
            setLoadingActive(false);
            props.nextPageProfileEdit();
        }).catch((err) => {
            setLoadingActive(false);            
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest||err.response.data.message=="Too many failed attempts. A new token must be issued."){
                    props.lastPageProfileEdit();
                }
                props.setMessageError(err.response.data.message)
            }
            else {
                props.setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    function handleClickCancel() {
        if(props.type!='upload'){
            window.location.reload();
        }
    }

    return (
        <form className="form-anauthenticated-authentication-two" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid rows={5} className="body-center-aunthentication">
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }
                <Grid.Row centered>
                    <h1 className="letter-title-authenticated">{t('authentication')}</h1>
                </Grid.Row>

                <Grid.Row className="center-checks-authenticated">
                    <p className="letter-data-body-authenticated"><span>{t('codeSentTo')}</span></p>
                </Grid.Row>

                <Grid.Row className="center-checks-authenticated" style={{ marginTop: '-13px' }}>
                    <p className="letter-data-body-authenticated" style={{ color: 'black' }}><span>{props.twoFactorSelected}</span></p>
                </Grid.Row>

                <Grid.Row className="center-checks-authenticated">
                    <p className="letter-data-body-authenticated"><span>{t('enterAuthenticationCode')}</span></p>
                    <p><input
                        style={{ width: '100%', marginRight: '112px' }}
                        type="text"
                        id="authenticationCode"
                        name="authenticationCode"
                        ref={register({ required: true })}
                    /></p>
                </Grid.Row>

                {errors.authenticationCode &&
                    <Grid.Row centered>
                        <span className="error-text">{t("AuthenticationCodeEmpty")}</span>
                    </Grid.Row>
                }

                <Grid.Row centered>
                    <button type="submit" className="button-circle" name="continue" id="continue" style={{ width: '35%', height: '47px', margin: 0 }}>{t('continue')}</button>
                    <Link to={'/profile'} onClick={handleClickCancel}
                        className="style-btn-cancel-authenticated" style={{ margin: '15px' }}>
                        {t('cancel')}</Link>
                </Grid.Row>

                <Grid.Row centered>
                    <p className="letter-data-body-authenticated"><span>{t('registerCondition')}</span></p>
                </Grid.Row>

            </Grid>
        </form>
    );
}
