import React, { Fragment, useState, useEffect } from "react";
import "./Body.css";
import { Link } from "react-router-dom";
import UnauthenticatedFooter from "./UnauthenticatedFooter";
import UnauthenticatedHeader from "./UnauthenticatedHeader";
import { useTranslation } from "react-i18next";
import { Container, Grid,  Dimmer, Loader } from 'semantic-ui-react';
import api from "../../api";

function Body({ children, admin }) {

    const url = window.location.href;
    const { t } = useTranslation();
    const [showMaintenance, setShowMaintenance] = useState(false);
    const [messageMaintenance, setMessageMaintenance] = useState();
    const [loadingActive, setLoadingActive] = useState();

    useEffect(() => {
        api.setSessionToken('-');
        loadWindowCheck();
    }, []);

    const loadWindowCheck = () => {
        setLoadingActive(true);
        api.checkWindows().then((resp) => {
            if (resp.data?.code === 'Closed') {
                setShowMaintenance(true);
                setMessageMaintenance(resp.data?.message);
            }
            setLoadingActive(false);
        }).catch((err) => {
            console.log(err)
            setLoadingActive(false);
        });
    }

    return (
        <Container className="rotech">
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

            <Grid className="body" columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <UnauthenticatedHeader admin={admin}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    <div className="navbar">
			    { showMaintenance &&
				    <>
				    <div class="maintenance-message">{messageMaintenance}</div>
				    </>
			    }
                    {url.includes('home') && !showMaintenance ?
                        <div>
                            {t('body_pleaseClick')} <Link style={{ textDecoration: 'underline' }} to={"/signin"}>{t('login')}</Link> {t('body_or')}
                            <Link style={{ textDecoration: 'underline' }} to={"/register"}>{t('body_createAccount')}</Link> {t('body_toContinue')}
                        </div>
                        :
                        <div>
                        </div>
		        
                    }
                </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    <div className="frame">
                        <div className="body-img">
                            {children}
                        </div>
                    </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <UnauthenticatedFooter />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default Body;
