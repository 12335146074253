import React from "react";
import { Grid } from 'semantic-ui-react';
import "./ProfileA.css";
import { useTranslation } from 'react-i18next';


export default function ProfileEmergencyContact(props) {
    const { t } = useTranslation();
    return (
        <Grid rows={2} style={{ width: '100%' }} id="two-column-emergency-profile-admin">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('firstName')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.firstName ? "" : props.dataEmergency.firstName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('lastName')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.lastName ? "" : props.dataEmergency.lastName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('contactType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.contactType ? "" : props.dataEmergency.contactType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phone')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.phone ? "" : props.dataEmergency.phone}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phoneType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.phoneType ? "" : props.dataEmergency.phoneType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile column-responsive-email-address-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.emailId ? "" : props.dataEmergency.emailId}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.emailType ? "" : props.dataEmergency.emailType}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}