import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dimmer, Divider, Grid, Loader } from 'semantic-ui-react';
import api from '../../api';
import Toast from "../common/Toast";
import "./ProfileA.css";
import ProfileEmergencyContact from './ProfileEmergencyContact';
import ProfileInsuranceInformation from './ProfileInsuranceInformation';
import ProfilePatientProfile from './ProfilePatientProfile';

export default function ProfileAHome(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const auth = useSelector(state => state.auth);
    let query = useQuery();
    const [loadingActive, setLoadingActive] = useState();
    const [dataProfileA, setDataProfileA] = useState();
    const [user, setUser] = useState();
    const [messageError, setMessageError] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        var sessionToken = localStorage.getItem('SessionToken');
        if (sessionToken && sessionToken != '-') {
            api.setSessionToken(sessionToken);
        }
        var patientId = query.get("patientId");
        setLoadingActive(true);
        axios.all([api.patientGetGet({ PatientId: parseInt(patientId), "GetProfile": true }),
        api.userGetGet({ PatientId: patientId })]).then((profResp) => {
            setDataProfileA(profResp[0].data.PatientData.profile);
            setUser(profResp[1].data.Usernames[0]);
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    }, [])

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function handleClickCancel() {
        history.replace("/manage-users");
    }

    function handleClickSubmit() {
        let body = {
            Username: user.Username,
            Status: user.Status === "Active" ? 'Inactive' : "Active"
        }
        setLoadingActive(true);
        api.userUpdatePost(body).then((resp) => {
            setUser({ ...user, Status: body.Status });
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    }


    return (
        <Grid rows={3}>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }
            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }
            <Grid columns='equal' divided inverted padded>
                <Grid.Row style={{ marginBottom: '4px' }}>
                    <Grid.Column className="width-column-tile-profile">
                        <span className="font-title" style={{ marginTop: 0 }}>{t('patientProfile')}</span>
                    </Grid.Column>
                    <Grid.Column>

                    </Grid.Column>
                    <Grid.Column className="buttons-profile" floated='right' textAlign='right' style={{ marginTop: '-6px', display: 'flex', flexDirection: 'row-reverse' }}>
                        {user &&
                            <button onClick={handleClickSubmit} className="button-circle profile-button" name="submit" id="submit" style={{ width: '100%', height: '40px', marginLeft: '18px' }}>
                                {user.Status === "Active" ?
                                    t('inactive')
                                    :
                                    t('active')
                                }
                            </button>
                        }
                        <button onClick={handleClickCancel} className="button-circle profile-button" name="cancel" id="cancel" style={{ width: '100%', height: '40px' }}>{t('returnToManageUsers')}</button>
                    </Grid.Column>

                </Grid.Row>
            </Grid>


            {dataProfileA &&
                <>
                    <Grid.Row className="body-container">
                        <ProfilePatientProfile dataPatient={dataProfileA.patientProfile} />
                        <div>
                            <Divider section />
                        </div>
                    </Grid.Row>
                    <span className="font-title" style={{ marginTop: 30 }}>{t('emergencyContactInformation')}</span>
                    <Grid.Row className="body-container">
                        <ProfileEmergencyContact dataEmergency={dataProfileA.emergencyContactInformation} />
                    </Grid.Row>
                    <span className="font-title" style={{ marginTop: 30 }}>{t('insuranceInformation')}</span>
                    <Grid.Row className="body-container">
                        <ProfileInsuranceInformation dataPrimary={dataProfileA.primaryInsuranceInformation} dataSecondary={dataProfileA.secondaryInsuranceInformation} />
                    </Grid.Row>
                </>
            }
        </Grid>
    );
}