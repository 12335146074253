import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import api from '../../api';
import Toast from "../common/Toast";
import "./ServiceHistory.css";
import ServiceHistoryDetailsList, { ServiceHistoryDocuments, ServiceHistoryTicketNumber } from "./ServiceHistoryDetailsList";

export default function ServiceHistoryDetails(props) {

    let { ticketNumber, patientId } = useParams();
    const [loadingActive, setLoadingActive] = useState(false);
    const [ticket, setTicket] = useState({ services: {}, documents: [], detail: [] });
    const [messageError, setMessageError] = useState();
    const [messageDuration, setMessageDuration] = useState();

    useEffect(() => {
        setLoadingActive(true);
        var sessionToken = window.localStorage.getItem('SessionToken');
        if (sessionToken && sessionToken != '-') {
            api.setSessionToken(sessionToken);
        }
        let payload = { TicketNumber: ticketNumber };
        if(patientId){
            payload.PatientId = patientId;
        }
        api.serviceGetGet(payload).then((resp) => {
            let st = resp.data.ServiceTicket;
            setTicket({services: st.Summary, documents: st.DocumentList, detail: st.DetailList});
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            setMessageDuration(6000);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            } else if (err.message == 'Network Error') {
                setMessageError(err.message)
                setMessageDuration(20000)
            } else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }, []);

    return (
        <>
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }
            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} duration={messageDuration} />
            }
            <Grid stackable columns={2}>
                <Grid.Column width={4}>
                    <ServiceHistoryTicketNumber openToast={props.openToast} setOpenToast={props.setOpenToast}
                        ticket={ticket} ticketNumber={ticketNumber}></ServiceHistoryTicketNumber>
                    <div style={{ marginTop: '25px' }}>
                        <ServiceHistoryDocuments setMessageError={setMessageError} openToast={props.openToast} setOpenToast={props.setOpenToast}
                            loadingActive={loadingActive} setLoadingActive={setLoadingActive} ticket={ticket} isSiteA={props.isSiteA}></ServiceHistoryDocuments>
                    </div>
                </Grid.Column>

                <Grid.Column width={11}>
                    <ServiceHistoryDetailsList openToast={props.openToast} setOpenToast={props.setOpenToast} ticket={ticket}></ServiceHistoryDetailsList>
                </Grid.Column>
            </Grid>
        </>
    );
}