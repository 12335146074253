import React from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';
import "./Profile.css";
import MaskedInput from 'react-maskedinput'


export const ProfileEditEmergencyContact = (props) => {
    const { t } = useTranslation();
    const infoRegister = props.register;
    const watchYes = props.watch('emailAddressEmergencyContact');
    const setValue = props.setValue;
    const emailTypes = ["Guarantor", "Facility", "Social Worker", "Care Giver", "Family-Other", "Friend", "Employer", "Other", "Legal", "Healthcare Power of Attorney", "Spouse", "Daughter", "Son"];
    return (
        <Grid rows={2} id="two-column-emergency-profile">
            <Grid.Row>
                <Grid stackable columns={4}>
                    <Grid.Row>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('firstName')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('firstName')} id="firstNameEmergencyContact" name="firstNameEmergencyContact" style={{ width: '100%' }} ref={infoRegister({ required: true })} /></p>
                            {props.errors.firstNameEmergencyContact ? <p className="mandatoryField">{t('requiredField')}</p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('lastName')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p><input aria-label={t('lastName')} style={{ width: '100%' }} id="lastNameEmergencyContact" name="lastNameEmergencyContact" ref={infoRegister({ required: true })} /></p>
                            {props.errors.lastNameEmergencyContact ? <p className="mandatoryField">{t('requiredField')}</p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('contactType')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p>
                                <select aria-label={t('contactType')} id="contactTypeEmergencyContact" name="contactTypeEmergencyContact" style={{ width: '100%', height: '22px' }} ref={infoRegister({ required: true })}>
                                    <option value=""></option>
                                    {emailTypes.map((et) => {
                                        return (
                                            <option value={et} key={et}>{et}</option>
                                        )
                                    })}
                                </select>
                            </p>
                            {props.errors.contactTypeEmergencyContact ? <p className="mandatoryField">{t('requiredField')}</p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row className="profileRow">
                <Grid stackable columns={4}>
                    <Grid.Row columns={4}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('phone')} <Icon className="mandatoryField" name='asterisk' /></span>

                            <p>
                                <input aria-label={t('phone')} id="phoneEmergencyContact" name="phoneEmergencyContact" type="hidden" ref={infoRegister({ required: true, pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/ })} />
                                <MaskedInput aria-label={t('phone')} mask="(111) 111-1111" value={props.dataEmergency.phone} onChange={(e) => { setValue('phoneEmergencyContact', e.target.value); }} style={{ width: '100%' }} />
                            </p>
                            {props.errors.phoneEmergencyContact ? <p className="mandatoryField">
                                {props.errors.phoneEmergencyContact.type == 'required' ? t('requiredField') : ''}
                                {props.errors.phoneEmergencyContact.type == 'pattern' ? t('phoneRequired') : ''}
                            </p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('phoneType')} <Icon className="mandatoryField" name='asterisk' /></span>
                            <p>
                                <select aria-label={t('phoneType')} id="phoneTypeEmergencyContact" name="phoneTypeEmergencyContact" style={{ width: '100%', height: '22px' }} ref={infoRegister({ required: true })}>
                                    <option value=""></option>
                                    <option value="Cell">Cell</option>
                                    <option value="Home">Home</option>
                                    <option value="Other">Other</option>
                                    <option value="Main">Main</option>
                                    <option value="Work">Work</option>
                                </select>
                            </p>
                            {props.errors.phoneTypeEmergencyContact ? <p className="mandatoryField">{t('requiredField')}</p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p><input aria-label={t('emailAddress')} type="email" id="emailAddressEmergencyContact" name="emailAddressEmergencyContact" style={{ width: '100%' }} ref={infoRegister({ required: false, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} /></p>
                            {props.errors.emailAddressEmergencyContact ? <p className="mandatoryField">
                                {props.errors.emailAddressEmergencyContact.type == 'required' ? t('requiredField') : ''}
                                {props.errors.emailAddressEmergencyContact.type == 'pattern' ? t('emailRequired') : ''}
                            </p> : null}
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}
                                {watchYes ? <Icon className="mandatoryField" name='asterisk' /> : null}
                            </span>
                            <p>
                                <select aria-label={t('emailType')} id="emailTypeEmergencyContact" name="emailTypeEmergencyContact" style={{ width: '100%', height: '22px' }} ref={infoRegister({ required: watchYes })}>
                                    <option value=""></option>
                                    <option value="Personal">Personal</option>
                                    <option value="Work">Work</option>
                                </select>
                            </p>
                            {props.errors.emailTypeEmergencyContact ? <p className="mandatoryField">{t('requiredField')}</p> : null}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid >
    );
}

export default function ProfileEmergencyContact(props) {
    const { t } = useTranslation();
    return (
        <Grid rows={2} style={{ width: '100%' }} id="two-column-emergency-profile">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('firstName')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.firstName ? "" : props.dataEmergency.firstName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('lastName')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.lastName ? "" : props.dataEmergency.lastName}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('contactType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.contactType ? "" : props.dataEmergency.contactType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phone')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.phone ? "" : props.dataEmergency.phone}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('phoneType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.phoneType ? "" : props.dataEmergency.phoneType}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile column-responsive-email-address-profile">
                            <span className="font-title-body">{t('emailAddress')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.emailId ? "" : props.dataEmergency.emailId}</p>
                        </Grid.Column>

                        <Grid.Column width={4} className="size-column-profile">
                            <span className="font-title-body">{t('emailType')}</span>
                            <p className="data-body">{!props.dataEmergency || !props.dataEmergency.emailType ? "" : props.dataEmergency.emailType}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}