import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Pagination, Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

export default function AuditLogList({ filterList, pagination, setPage, sortColumn, sortDirection, setSortColumn, setSortDirection, dataAuditLog }) {
    const { t } = useTranslation();

    const toggleSort = (column) => {
        if (sortColumn === column) {
            if (sortDirection === 'asc') {
                setSortDirection('desc');
            } else {
                setSortDirection('asc');
            }
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    }

    const onPageChange = (evt, data) => {
        setPage(data.activePage);
    }

    const TableRow = ({ item }) => {
        return <Table.Row key={item.PatientID}>
            <Table.Cell>{item.Type}</Table.Cell>
            <Table.Cell>{item.PatientId}</Table.Cell>
            <Table.Cell>{item.AffectedUser}</Table.Cell>
            <Table.Cell>{item.Username}</Table.Cell>
            <Table.Cell>{moment(item.Date).format("MM-DD-YYYY")}</Table.Cell>
        </Table.Row>
    };

    return (
        <Grid padded='vertically' className="margin-list" style={{ marginLeft: 25, marginRight: 25 }}>
            <Grid.Column>
                <Table basic='very' fixed compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => { toggleSort('Type') }} className="letter-title-service-history-list">
                                {t('type')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "Type" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "Type" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('PatientId') }} className="letter-title-service-history-list">
                                {t('patientId')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "PatientId" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "PatientId" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('AffectedUser') }} className="letter-title-service-history-list">
                                {t('affectedUser')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "AffectedUser" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "AffectedUser" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('Username') }} className="letter-title-service-history-list">
                                {t('user')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "Username" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "Username" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('Date') }} className="letter-title-service-history-list">
                                {t('date')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn === "Date" && sortDirection === 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn === "Date" && sortDirection === 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dataAuditLog.map((auditLog) => {
                            return (<TableRow key={auditLog.PatientID} item={auditLog}></TableRow>);
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell textAlign='left' style={{ position: 'relative' }}>
                                <p className="pageCount">{t('pageNumber')} {pagination.PageNumber} {t('pageOf')} {pagination.TotalPages}</p>
                            </Table.HeaderCell>

                            <Table.HeaderCell colSpan='5' textAlign='right' style={{ position: 'relative' }}>
                                <Pagination
                                    style={{ marginRight: '2em' }}
                                    activePage={pagination.PageNumber}
                                    totalPages={pagination.TotalPages}
                                    firstItem={null}
                                    lastItem={null}
                                    ellipsisItem={null}
                                    onPageChange={onPageChange}
                                    nextItem={t('next')}
                                    prevItem={t('prev')}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid>
    );
}
