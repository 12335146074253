import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import "./Profile.css";
import moment from 'moment';

export const ProfileEditInsuranceInformation = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const actionButtons = (url) => {
        history.replace(url);
    }

    return (
        <Grid rows={2} style={{ width: '100%' }} id="two-column-insurance-profile">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('primary')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyName ? "" : props.dataPrimary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyNumber ? "" : props.dataPrimary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.groupNumber ? "" : props.dataPrimary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.effectiveDate ? "" : moment(props.dataPrimary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>

                        <Grid.Column width={2} style={{ display: 'flex' }} className="margin-responsive-buttons-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('secondary')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyName ? "" : props.dataSecondary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyNumber ? "" : props.dataSecondary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.groupNumber ? "" : props.dataSecondary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.effectiveDate ? "" : moment(props.dataSecondary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>

                        <Grid.Column width={2} style={{ display: 'flex' }} className="margin-responsive-buttons-profile">
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}


export default function ProfileInsuranceInformation(props) {
    const { t } = useTranslation();
    const history = useHistory();

    const actionButtons = (url) => {
        history.replace(url);
    }

    return (
        <Grid rows={2} style={{ width: '100%' }} id="two-column-insurance-profile">
            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('primary')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyName ? "" : props.dataPrimary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.policyNumber ? "" : props.dataPrimary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.groupNumber ? "" : props.dataPrimary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataPrimary || !props.dataPrimary.effectiveDate ? "" : moment(props.dataPrimary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>

                        <Grid.Column width={2} style={{ display: 'flex' }} className="margin-responsive-buttons-profile">
                            <button onClick={() => actionButtons('/profile-upload-primary')} className="button-circle" name="changePrimary" id="changePrimary" style={{ width: '122px', height: '40px' }}>{t('btnChangePrimary')}</button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>

            <Grid.Row>
                <Grid stackable columns={5}>
                    <Grid.Row columns={5}>
                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('secondary')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyName ? "" : props.dataSecondary.policyName}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('policyNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.policyNumber ? "" : props.dataSecondary.policyNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('groupNumber')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.groupNumber ? "" : props.dataSecondary.groupNumber}</p>
                        </Grid.Column>

                        <Grid.Column className="size-column-profile">
                            <span className="font-title-body">{t('effectiveDate')}</span>
                            <p className="data-body">{!props.dataSecondary || !props.dataSecondary.effectiveDate ? "" : moment(props.dataSecondary.effectiveDate).format('MM/DD/YYYY')}</p>
                        </Grid.Column>

                        <Grid.Column width={2} style={{ display: 'flex' }} className="margin-responsive-buttons-profile">
                            <button onClick={() => actionButtons('/profile-upload-secondary')} className="button-circle" name="changeSecondary" id="changeSecontary" style={{ width: '122px', height: '40px' }}>{t('btnChangeSecondary')}</button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
        </Grid>
    );
}
