import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from 'semantic-ui-react';
import api from "../../api";
import "./Profile.css";

const ProfileChangePassword = (props) => {
    const [approved, setApproved] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const { t } = useTranslation();
    const Auth = useSelector(state => state.auth);
    const history = useHistory();
    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            reEnterNewPassword: ""
        }
    })

    function actionButtonSubmit() {
        let values = getValues();
        let body = {
            "Username": window.localStorage.getItem('username'),
            "OldPassword": values.currentPassword,
            "Password": values.newPassword.trim()
        }

        if (!passwordValidation(values.newPassword.trim(), values.reEnterNewPassword.trim())) {
            setErrorPass(true);
        }
        else {
            setErrorPass(false);
            setApproved(true);
            props.setLoadingActive(true);
            api.userUpdatePost(body)
                .then((response) => {
                    props.setNewPassword(values.newPassword.trim());
                    props.setOldPassword(values.currentPassword);
                    props.setLoadingActive(false);
                    props.nextPageForgotPassword();
                }).catch((err) => {
                    props.setLoadingActive(false);
                    if (err.response && err.response.data.message) {
                        props.setMessageError(err.response.data.message)
                    }
                    else {
                        props.setMessageError(err.message)
                    }
                    props.setOpenToast(true);
                })
        }
    }

    function passwordValidation(pass, confirmPass) {

        if (!pass || !confirmPass) {
            return false;
        }
        var request = true;
        if (pass.length < 8) {
            request = false;
        }

        var hasNumber = /\d/;
        if (!hasNumber.test(pass)) {
            request = false;
        }

        var hasUppercase = /[A-Z]|[\u0080-\u024F]/;
        if (!hasUppercase.test(pass)) {
            request = false;
        }

        if (pass != confirmPass) {
            request = false;
        }

        return request;
    }

    function handleClickCancel() {
        history.replace('profile');
    }


    return (
        <form className="forgotPasswordForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid rows={6} columns={1} width={4} centered>
                <Grid.Column className="changePasswordProfile">
                    <Grid.Row className="title">
                        <span className="title-change-password">{t('changePassword')}</span>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="font-title-body">{t('enterCurrentPassword')}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <p><input type="password" id="currentPassword" name="currentPassword" ref={register({ required: true })} /></p>
                    </Grid.Row>

                    <Grid.Row>
                        <p className="letter-data-change-password"><span>{t('textChangePassword1')}</span></p>
                        <p className="letter-data-change-password"><span>{t('textChangePassword2')}:</span></p>
                        <p className="letter-data-change-password" style={{ paddingLeft: '20px' }}><span>{t('textChangePassword3')}</span></p>
                        <p className="letter-data-change-password" style={{ paddingLeft: '20px' }}><span>{t('textChangePassword4')}</span></p>
                        <p className="letter-data-change-password" style={{ paddingLeft: '20px' }}><span>{t('textChangePassword5')}</span></p>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="font-title-body">{t('enterNewPassword')}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <p><input id="newPassword" type="password" name="newPassword" ref={register({ required: true })} /></p>
                    </Grid.Row>

                    <Grid.Row>
                        <span className="font-title-body">{t('reEnterNewPassword')}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <p><input
                            id="reEnterNewPassword" name="reEnterNewPassword"
                            style={{ width: '30%' }}
                            type="password"
                            ref={register({ required: true })} /></p>
                    </Grid.Row>

                    {errorPass &&
                        <Grid.Row centered>
                            <span className="error-text">{t("passwordDoesNotMeetTheNecessaryRequirements")}</span>
                        </Grid.Row>
                    }

                    {errors.currentPassword &&
                        <Grid.Row centered>
                            <span className="error-text">{t("IncompletePasswordField")}</span>
                        </Grid.Row>
                    }
                    {errors.newPassword &&
                        <Grid.Row centered>
                            <span className="error-text">{t("NewPasswordEmptyField")}</span>
                        </Grid.Row>
                    }
                    {errors.reEnterNewPassword &&
                        <Grid.Row centered>
                            <span className="error-text">{t("ReEnterNewPasswordEmptyField")}</span>
                        </Grid.Row>
                    }

                    <Grid.Row style={{ textAlign: 'center' }}>
                        <button type="submit" className=" button-circle" name="submit" id="submit" style={{ width: '100px', height: '37px', margin: 0, marginRight: 10 }}>{t('submitUpperCase')}</button>
                        <button onClick={handleClickCancel} className="button-circle" name="submit" id="submit" style={{ width: '100px', height: '37px', margin: 0 }}>CANCEL</button>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </form>
    );
}

export default ProfileChangePassword;