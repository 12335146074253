import React, { useState } from "react";
import { Dimmer, Loader } from 'semantic-ui-react';
import AuthenticatedAuthentication from "../common/AuthenticatedAuthentication";
import AuthenticatedAuthenticationTwo from "../common/AuthenticatedAuthenticationTwo";
import ProfileEditHome from "./ProfileEditHome";
import ProfileHome from "./ProfileHome";
import ProfileConfirmEdit from "./ProfileConfirmEdit";
import Toast from "../common/Toast";
import api from "../../api";


const ComponentsProfile = ({ openToast, setOpenToast }) => {
    const [page, setPage] = useState(1);
    const [username, setUsername] = useState('');
    const nextPageProfileEdit = () => setPage(p => p + 1);
    const lastPageProfileEdit = () => setPage(p => p - 1);
    const redirectConfirm = () => setPage(5);
    const resetPages = () => {
        setPage(1);
    };
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [loadingActive, setLoadingActive] = useState();
    const [messageError, setMessageError] = useState();
    const [bodyProfileEdit, setBodyProfileEdit] = useState();

    const sendPin = () => {
        let bodyContact = { ...bodyProfileEdit,
            ChallengeResponse: {
                ContactOption: twoFactorSelected,
                Type: '2FA'
            }
        }
        setLoadingActive(true);
        api.patientUpdatePost(bodyContact).then((response) => {
            setLoadingActive(false);
            nextPageProfileEdit();
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            setOpenToast(true);
        });;
    }

    return (
        <>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {openToast &&
                <Toast message={messageError} type="error" openToast={openToast} setOpenToast={setOpenToast} />
            }

            {page === 1 &&
                <ProfileHome
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={openToast} setOpenToast={setOpenToast} page={page}
                    lastPageProfileEdit={lastPageProfileEdit}
                    nextPageProfileEdit={nextPageProfileEdit} />}

            {page === 2 &&
                <ProfileEditHome
                    bodyProfileEdit={bodyProfileEdit} setBodyProfileEdit={setBodyProfileEdit}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={openToast} setOpenToast={setOpenToast}
                    redirectConfirm={redirectConfirm} setUsername={setUsername} lastPageProfileEdit={lastPageProfileEdit}
                    nextPageProfileEdit={nextPageProfileEdit} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} />}

            {page === 3 &&
                <AuthenticatedAuthentication type={'profileEdit'}
                    username={username} lastPageProfileEdit={lastPageProfileEdit}
                    nextPageProfileEdit={nextPageProfileEdit}
                    twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} sendPin={sendPin} />}

            {page === 4 &&
                <AuthenticatedAuthenticationTwo
                    bodyProfileEdit={bodyProfileEdit} setBodyProfileEdit={setBodyProfileEdit}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={openToast} setOpenToast={setOpenToast}
                    type={'profileEdit'}
                    nextPageProfileEdit={nextPageProfileEdit}
                    username={username} lastPageProfileEdit={lastPageProfileEdit} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} />}

            {page === 5 &&
                <ProfileConfirmEdit type={'profileEdit'} setPage={setPage} resetPages={resetPages} />}
        </>
    );
};

export default ComponentsProfile;