import React, { useState } from "react";
import CreateAccount from "./CreateAccount";
import CreateAccountPassword from "./CreateAccountPassword";
import MessageConfirm from "./MessageConfirm";
import UnauthenticatedAuthentication from "./UnauthenticatedAuthentication";
import UnauthenticatedAuthenticationTwo from "./UnauthenticatedAuthenticationTwo";
import api from "../../api";
import { Dimmer, Loader } from 'semantic-ui-react';
import Toast from "../common/Toast";
import { Redirect } from "react-router-dom";
import ReactGA from 'react-ga';

const ComponentsRegister = (props) => {
    const [page, setPage] = useState(1);
    const nextPageRegister = () => setPage(p => p + 1);
    const lastPageRegister = () => setPage(p => p - 1);
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [loadingActive, setLoadingActive] = useState();
    const [messageError, setMessageError] = useState();
    const [patientId, setPatientId] = useState();

    const sendPin = () => {
        let bodyContact = {
            Username: window.localStorage.getItem('username'),
            ChallengeResponse: {
                ContactOption: twoFactorSelected,
                Type: '2FA'
            }
        }
        setLoadingActive(true);
        api.patientValidatePost(bodyContact).then((response) => {
            setLoadingActive(false);
            nextPageRegister();
        }).catch((err) => {
            ReactGA.event({category: 'Registration', action: 'Failed Registration', label: 'Failed MFA Send'});
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const validatePin = (values) => {

        let body = {
            "Username": window.localStorage.getItem('username'),
            "ChallengeResponse": {
                "Type": "2FA",
                "MfaPin": values.authenticationCode
            }
        }

        setLoadingActive(true);
        api.patientValidatePost(body).then((response) => {
            setLoadingActive(false);
            nextPageRegister();
        }).catch((err) => {
            setLoadingActive(false);
            ReactGA.event({category: 'Registration', action: 'Failed Registration', label: 'Failed MFA Attempt'});
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest){
                    lastPageRegister();
                }
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    return (
        <>
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {page === 1 &&
                <CreateAccount
                    setLoadingActive={setLoadingActive} loadingActive={loadingActive}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    lastPageRegister={lastPageRegister}
                    setPatientId={setPatientId}
                    nextPageRegister={nextPageRegister} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/register"} />}

            {page === 2 &&
                <UnauthenticatedAuthentication
                    setLoadingActive={setLoadingActive} loadingActive={loadingActive}
                    sendPin={sendPin} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/unauthenticated-authentication-password"} />}

            {page === 3 &&
                <UnauthenticatedAuthenticationTwo validatePin={validatePin} twoFactorSelected={twoFactorSelected}
                    setLoadingActive={setLoadingActive} loadingActive={loadingActive}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/unauthenticated-authenticationTwo-password"} />}

            {page === 4 &&
                <CreateAccountPassword
                    setLoadingActive={setLoadingActive} loadingActive={loadingActive}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    patientId={patientId}
                    lastPageRegister={lastPageRegister}
                    nextPageRegister={nextPageRegister} path={"/register"} />}

            {page === 5 &&
                 <Redirect to="/signin" />}
        </>
    );
};

export default ComponentsRegister;
