import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Dimmer, Icon } from 'semantic-ui-react';
import api from '../../api';
import imagePdf from "../../img/u1006.png";
import "./ServiceHistory.css";
import moment from 'moment';
function createData(service, qty, description, tracking) {
    return { service, qty, description, tracking };
}

function createDataDocuments(name) {
    return { name };
}

export const ServiceHistoryTicketNumber = ({ ticket, ticketNumber }) => {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <TableContainer component={Paper} style={{ borderRadius: '0' }}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <tr>
                        <td colSpan="4" style={{ backgroundColor: '#0061a7', color: 'white', height: '35px' }}><strong className="font-title-table padded">{t('ticketNumber')}: {ticketNumber}</strong></td>
                    </tr>
                </TableHead>
                <TableBody key="ticketNumber">
                    <TableRow key={t('dateOfService')}>
                        <TableCell key={ticket.services.dateOfService} component="th" scope="row"><span className="letter-details-gray">{t('dateOfService')} : {moment(ticket.services.dateOfService).format('MM/DD/YYYY')}</span></TableCell>
                    </TableRow>
                    <TableRow key={t('orderType')}>
                        <TableCell key={ticket.services.orderType} component="th" scope="row"><span className="letter-details-gray">{t('orderType')} : {ticket.services.orderType}</span></TableCell>
                    </TableRow>
                    <TableRow key={t('status')} >
                        <TableCell key={ticket.services.status} component="th" scope="row"><span className="letter-details-gray">{t('status')} : {ticket.services.status}</span></TableCell>
                    </TableRow>
                    <TableRow key={t('servicingLocation')}>
                        <TableCell key={ticket.services.servicingLocation} component="th" scope="row"><span className="letter-details-gray">{t('servicingLocation')} : {ticket.services.servicingLocation}</span></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const ServiceHistoryDocuments = ({ setMessageError, setOpenToast, isSiteA, ticket, setLoadingActive, loadingActive }) => {
    const { t } = useTranslation();
    let { ticketNumber } = useParams();
    function handleClickDownload(id) {
        setLoadingActive(true);
        let payload = { "DocumentIdList": [id] }
        api.documentGetGet(payload).then((resp) => {
            window.open(resp.data.DocumentList[0])
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            setOpenToast(true);
        });
    }

    function handleClickDownloadAll() {
        setLoadingActive(true);
        let ids = ticket.documents.map((doc) => {
            return doc.id;
        })
        let payload = { "DocumentIdList":[], "TicketNumber": ticketNumber }
        api.documentGetGet(payload).then((resp) => {
            resp.data.DocumentList.map((doc) => {
                window.open(doc);
            });
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            setOpenToast(true);
        });
    }


    return (
        <TableContainer component={Paper} style={{ borderRadius: '0' }}>
            {loadingActive &&
                <Dimmer active inverted>
                </Dimmer>
            }
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <tr>
                        <td colSpan="4" style={{ backgroundColor: '#0061a7', color: 'white', height: '35px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <strong className="font-title-table">
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        {t('documents')}

                                    </Grid>
                                    {!isSiteA ?
                                        <Grid item>
                                            <Icon className="border-bottom-icon" name='download' style={{ cursor: 'pointer', paddingBottom:21 }} onClick={handleClickDownloadAll} />
                                        </Grid>
                                        : ""}
                                </Grid>
                            </strong>
                        </td>
                    </tr>
                </TableHead>
                <TableBody key="documents">
                    {ticket.documents.map(row => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row"><span className="letter-details-gray">{row.name}</span>
                            {!isSiteA ?
                                <img
                                    className="border-bottom-img"
                                    onClick={function () { handleClickDownload(row.id); }}
                                    src={imagePdf} alt="logo"
                                    width={25} style={{ float: 'right', textDecoration: 'underline', cursor: 'pointer' }} />
                            :null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function ServiceHistoryDetailsList(props, { ticket }) {
    const { t } = useTranslation();

    return (
        <TableContainer key="containerDetailsList" component={Paper} style={{ borderRadius: '0' }}>
            <Table key="tableDetailsList" size="small" aria-label="a dense table">
                <TableHead>
                    <tr>
                        <td colSpan="4" style={{ backgroundColor: '#0061a7', color: 'white', height: '35px' }}><strong className="font-title-table padded">{t('details')}</strong></td>
                    </tr>
                    <TableRow key="Services">
                        <TableCell key="service">{t('service')}</TableCell>
                        <TableCell key="Qty" align="left">{t('quantity')}</TableCell>
                        <TableCell key={t('description')} align="left">{t('description')}</TableCell>
                        <TableCell key={t('trackingNumber')} align="left">{t('trackingNumber')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody key="details">
                    {props.ticket.detail.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell key={row.service} component="th" scope="row"><span className="letter-details-gray">{row.service}</span></TableCell>
                            <TableCell key={row.qty} align="left"><span className="letter-details-gray">{row.qty}</span></TableCell>
                            <TableCell key={row.description} align="left"><span className="letter-details-gray">{row.description}</span></TableCell>
                            <TableCell key={row.tracking} align="left"><span className="letter-details-gray">{row.trackingNumber}</span></TableCell></TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
