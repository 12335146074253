import React, { useState } from "react";
import { Dimmer, Loader, Grid } from 'semantic-ui-react';
import api from "../../api";
import Toast from "../common/Toast";
import ProfileChangePassword from "./ProfileChangePassword";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ComponentsChangePassword = (props) => {
    const [page, setPage] = useState(1);
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const nextPageForgotPassword = () => setPage(p => p + 1);
    const lastPageForgotPassword = () => setPage(p => p - 1);
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [loadingActive, setLoadingActive] = useState();
    const [messageError, setMessageError] = useState();
    const history = useHistory();
    const {t} = useTranslation();

    const goToProfile = () => {
        history.replace('profile');
    }

    return (
        <>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            {page === 1 &&
                <ProfileChangePassword
                    setNewPassword={setNewPassword}
                    setOldPassword={setOldPassword}
                    setLoadingActive={setLoadingActive}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    setUsername={setUsername} lastPageForgotPassword={lastPageForgotPassword}
                    nextPageForgotPassword={nextPageForgotPassword} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} />}

            {page === 2 &&
            <Grid rows={2} className="body-center confirm" style={{paddingTop:'150px',paddingBottom:'150px'}}>
                <Grid.Row centered>
                    <span className="title-upload-card-confirm">{t('changePassword_review_1')}</span>
                </Grid.Row>

                <Grid.Row centered>
                    <button onClick={goToProfile} className="button-circle" name="continue" id="continue" style={{ width: '19%', height: '37px', margin: 0 }}>{t('continue')}</button>
                </Grid.Row>
            </Grid>
            }

        </>
    );
};

export default ComponentsChangePassword;
