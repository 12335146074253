import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Dimmer, Loader, Divider, Grid } from 'semantic-ui-react';
import "./ServiceHistory.css";
import { useHistory, useLocation } from "react-router-dom";
import ServiceHistoryList from './ServiceHistoryList';
import { useTranslation } from 'react-i18next';
import { DateInput } from 'semantic-ui-calendar-react';
import { Message } from 'semantic-ui-react'
import { useForm } from "react-hook-form";
import api from '../../api';
import Toast from "../common/Toast";
import moment from 'moment';

export default function ServiceHistoryHome(props) {
    const { t } = useTranslation();
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [loadingActive, setLoadingActive] = useState(false);
    const [page, setPage] = useState(1);
    const [errorAPI, setErrorAPI] = useState(false);
    const [emptyResult, setEmptyResult] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [serviceList, setServiceList] = useState([]);
    const [sortColumn, setSortColumn] = useState("ticketNumber");
    const [sortDirection, setSortDirection] = useState("asc");
    const [pagination, setPagination] = useState({ TotalPages: 0, TotalItems: 0, ItemsPerPage: 10, PageNumber: 1 });
    const [activePage, setActivePage] = useState(1);
    const [statuses, setStatuses] = useState([{ Code: "SHIPPED", Description: "Shipped" }, { Code: "PENDING", Description: "Pending" }, { Code: "COMPLETED", Description: "Completed" }]);
    const [orderTypes, setOrderTypes] = useState([{ Code: "1", Description: "O2, Respiratory Supplies" }, { Code: "2", Description: "Sleep Therapy & Supplies" }, { Code: "3", Description: "Wheel Chairs & Supplies" }]);
    const [messageError, setMessageError] = useState();
    const [firstLoad, setFirstLoad] = useState(true);
    let query = useQuery();

    const handleDateChange = (event, { name, value }) => {
        if (name === 'dateToFilter') {
            setDateTo(value);
        }

        if (name === 'dateFromFilter') {
            setDateFrom(value);
        }
    };

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            dateFromFilter: undefined,
            dateToFilter: undefined,
            orderTypeFilter: undefined,
            statusFilter: undefined
        }
    });

    function actionButtonSubmit(sub) {
        filterList(true);
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        var sessionToken = localStorage.getItem('SessionToken');
        if (sessionToken && sessionToken != '-') {
            api.setSessionToken(sessionToken);
        }
        loadDependencies();
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            if (page === 1) {
                setLoadingActive(true);
                filterList();
            }
            else {
                setPage(1);
            }
        }
        else {
            setFirstLoad(false);
        }
    }, [sortColumn, sortDirection]);

    useEffect(() => {
        setLoadingActive(true);
        filterList();
    }, [page]);

    const loadDependencies = () => {
        let payload = { FilterList: true };
        api.serviceGetGet(payload).then((resp) => {
            if (resp.data.OrderTypes) {
                setOrderTypes(resp.data.OrderTypes);
            }
            if (resp.data.Statuses) {
                setStatuses(resp.data.Statuses);
            }
        }).catch((err) => {
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const filterList = (resetPagination = false) => {
        window.scrollTo(0, 0);
        setLoadingActive(true);
        let values = getValues();
        let filter = {
        };
        if (values.statusFilter && values.statusFilter !== '0') {
            filter.Status = values.statusFilter;
        }
        if (values.orderTypeFilter && values.orderTypeFilter != "NULL") {
            filter.OrderType = values.orderTypeFilter;
        }
        if (dateFrom) {
            filter.FromDate = moment(dateFrom, ['MM-DD-YYYY', 'DD-MM-YYYY']).toDate();
        }
        if (dateTo) {
            filter.ToDate = moment(dateTo, ['MM-DD-YYYY', 'DD-MM-YYYY']).toDate();
        }
        var patientId = query.get("patientId");
        let payload = { Filters: [filter], Pagination: { PageNumber: (resetPagination ? 1 : page), SortColumn: sortColumn, SortDirection: sortDirection } };
        if (patientId) {
            payload.PatientId = patientId;
        }
        api.serviceGetGet(payload).then((resp) => {
            setErrorAPI(false);
            setEmptyResult(false);
            if (!resp.data.Pagination) {
                resp.data.Pagination = { TotalPages: 1, TotalItems: 1, ItemsPerPage: 1, PageNumber: 1, StartItem: 1, EndItem: 1 };
            }
            setServiceList(resp.data.ServiceTicketSummaryList);

	    setEmptyResult(resp.data.ServiceTicketSummaryList.length == 0);

            setPagination(resp.data.Pagination);
            if (resp.data.OrderTypes) {
                setOrderTypes(resp.data.OrderTypes);
            }
            if (resp.data.Statuses) {
                setStatuses(resp.data.Statuses);
            }
            setLoadingActive(false);
        }).catch((err) => {
            setErrorAPI(true);
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const clearFilters = () => {
        setValue("orderTypeFilter", undefined);
        setValue("statusFilter", undefined);
        setDateTo('');
        setDateFrom('');
    }

    return (
        <>
            <div className="service-history-message">{t('serviceHistoryMessage')}</div>

            <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }
                {props.openToast &&
                    <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
                }
                <Grid rows={3}>
                    <Grid.Row className="fix-padding-row filters">
                        <Grid stackable columns={4}>
                            <Grid.Row className="fix-padding-row">
                                <Grid.Column>
                                    <p className="letter-name-filter position-title-filter-date" style={{ color: 'black' }}><span>{t('DATEOFSERVICE')}</span></p>
                                    <strong className="letter-name-filter">{t('dateFrom')}:</strong>
                                    <DateInput
                                        aria-label={t('dateFrom')}
                                        dateFormat="MM-DD-YYYY"
                                        closeOnMouseLeave={false}
                                        closable={true}
                                        name="dateFromFilter"
                                        id="dateFromFilter"
                                        placeholder={t('dateFrom')}
                                        iconPosition="left"
                                        className="date-filter max-width-date-filter-service-history"
                                        value={dateFrom}
                                        animation="none"
                                        onChange={handleDateChange}
                                        ref={register({ required: false })}
                                    />
                                </Grid.Column>

                                <Grid.Column className="display-date-filter-down-service-history">
                                    <strong className="letter-name-filter">{t('dateTo')}:</strong>
                                    <DateInput
                                        aria-label={t('dateTo')}
                                        dateFormat="MM-DD-YYYY"
                                        closable={true}
                                        name="dateToFilter"
                                        placeholder={t('dateTo')}
                                        iconPosition="left"
                                        value={dateTo}
                                        className="date-filter max-width-date-filter-service-history"
                                        animation="none"
                                        onChange={handleDateChange}
                                        ref={register({ required: false })}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <p className="letter-name-filter width-filters" style={{ color: 'black' }}><span>{t('ORDERTYPE')}</span></p>
                                    <select optgroup aria-label={t('ORDERTYPE')} defaultValue={undefined} className="width-filter-order-type max-width-filters-service-history" name="orderTypeFilter" id="orderTypeFilter" ref={register({ required: false })}>
                                        <option value='NULL'></option>
                                        <optgroup>
                                            {orderTypes.map((ot) => {
                                                return (<option key={ot.Code} value={ot.Code}>{ot.Description}</option>);
                                            })}
                                        </optgroup>
                                    </select>
                                </Grid.Column>

                                <Grid.Column className="margin-column-status">
                                    <p className="letter-name-filter" style={{ color: 'black' }}><span>{t('STATUS')}</span></p>
                                    <select aria-label={t('STATUS')} defaultValue={undefined} className="width-filter-status max-width-filters-service-history" name="statusFilter" id="statusFilter" ref={register({ required: false })}>
                                        <option value='0'></option>
                                        {statuses.map((ot) => {
                                            return (<option key={ot.Code} value={ot.Code}>{ot.Description}</option>);
                                        })}
                                    </select>
                                </Grid.Column>

                                <Grid.Column className="lastColumn view-btn-filter-service-history-up" style={{ alignSelf: 'flex-end' }}>
                                    <button className="button-circle size-button-filters size-btn-service-history" name="filter">{t('filter')}</button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>

                    <Grid.Row className="fix-padding-row filters">
                        <Grid stackable columns={4}>
                            <Grid.Row className="fix-padding-row">

                                <Grid.Column className="display-date-filte-up-service-history">
                                    <strong className="letter-name-filter">{t('dateTo')}:</strong>
                                    <DateInput
                                        aria-label={t('dateTo')}
                                        dateFormat="MM-DD-YYYY"
                                        closable={true}
                                        name="dateToFilter"
                                        placeholder={t('dateTo')}
                                        iconPosition="left"
                                        value={dateTo}
                                        className="date-filter max-width-date-filter-service-history"
                                        animation="none"
                                        onChange={handleDateChange}
                                        ref={register({ required: false })}
                                    />
                                </Grid.Column>

                                <Grid.Column only="computer">
                                </Grid.Column>

                                <Grid.Column only="computer">
                                </Grid.Column>

                                <Grid.Column className="view-btn-filter-service-history-down lastColumn" style={{ alignSelf: 'flex-end' }}>
                                    <button className="button-circle size-button-filters size-btn-service-history" name="filter">{t('filter')}</button>
                                </Grid.Column>

                                <Grid.Column className="lastColumn" style={{ alignSelf: 'flex-end' }}>
                                    <button onClick={clearFilters} className="button-circle size-button-filters size-btn-service-history" name="clearFilter">{t('clearFilter')}</button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row >

                    {errorAPI ?
                        <Message negative className="message-error-load">
                            <Message.Header>ERROR</Message.Header>
                            <p>{t('errorLoadingData')}</p>
                        </Message>
                        :
	                emptyResult ?
                            <Message negative className="message-error-load">
                                <p>{t('noServicesFound')}</p>
                                <p>{t('serviceHistoryMessage')}</p>
                            </Message>
	                    :
                            <>
                                <Divider section />
                                <Grid.Row className="fix-padding-row">

                                    <ServiceHistoryList
                                        sortColumn={sortColumn} setSortColumn={setSortColumn}
                                        sortDirection={sortDirection} setSortDirection={setSortDirection}
                                        filterList={filterList}
                                        serviceList={serviceList} pagination={pagination} setPage={setPage} page={page} isSiteA={props.isSiteA} className="margin-list">
                                    </ServiceHistoryList>
                                </Grid.Row>
                            </>
                    }
                </Grid>
            </form>
        </>
    );
}
