import _ from "lodash";
import moment from 'moment';
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Dimmer, Grid, Loader, Icon } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import api from "../../api";
import "./ForgotUsername.css";

const ForgotUsername = (props) => {
    const history = useHistory();
    const [loadingActive, setLoadingActive] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const { t } = useTranslation();
    const months = _.range(12);
    const days = _.range(1, 32);
    const years = _.range(1900, new Date().getFullYear() + 1);

    const { register, setValue, handleSubmit, errors, getValues, watch, clearError, setError } = useForm({
        defaultValues: {
            // patientId: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            emailAddress: undefined,
            dobDay: 1,
            dobMonth: 0,
            dobYear: 1900,
            dob: '1900-01-01',
            captcha: undefined
        }
    });

    function actionButtonSubmit(sub) {
        let values = getValues();

        if (!values.phoneNumber && !values.emailAddress) {
            setError("missingSecondary", {
                type: "manual",
                message: "Either Phone Number or Email Address is mandatory."
            })
            return;
        }

        let body = {
            "DateOfBirth": values.dob,
            "FamilyName": values.lastName,
            "PatientId": "",
            "PhoneNumber": values.phoneNumber,
            "EmailAddress": values.emailAddress,
            "Lookup": true,
            "ChallengeResponse": {
                "Type": "CAPTCHA",
                "ReCAPTCHAResponse": { "Answer": values.captcha }
            }
        }
        setLoadingActive(true);
        api.patientValidatePost(body)
            .then((response) => {
                if (response.data.code === "Success") {
                    api.setSessionToken(response.data.SessionToken);
                    props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                    props.nextPageForgotSurname();
                    setLoadingActive(false);
                }
                else {
                    setLoadingActive(false);
                    window.grecaptcha.reset();
                    setSubmitError(true);
                }
            })
            .catch((err) => {
                window.grecaptcha.reset();
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
    }

    const solveCaptcha = (response) => {
        setValue('captcha', response);
    }

    const setDob = () => {
        let vals = getValues();
        let mom = moment({ y: vals.dobYear, M: vals.dobMonth, d: vals.dobDay });
        if (mom.isValid()) {
            setValue('dob', mom.format("YYYY-MM-DD"));
        } else {
            setValue('dob', undefined);
        }
    }

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid columns={1} rows={6} className="body-grid-forgot-username">
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

                <Grid.Row centered>
                    <span className="letter-title-forgot-username">{t("forgotUsername_title")}</span>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-forgot-username">
                        {t('lastName')} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input aria-label={t("lastName")} type="text" id="lastName" name="lastName" className="input-forgot-username" ref={register({ required: true })} />
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-forgot-username">{t("dateOfBirth")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <div className="padding-horizontal-date-forgot-username">
                        <select aria-label='month' onChange={() => { setDob() }} className="select-date-forgot-username" name="dobMonth" ref={register({ required: true })}>
                            {months.map(m => {
                                return (
                                    <option value={m}>{t('month_short_' + m)}</option>
                                )
                            })}
                        </select>
                        <select aria-label='day' onChange={() => { setDob() }} className="select-date-forgot-username" name="dobDay" ref={register({ required: true })}>
                            {days.map(d => {
                                return (
                                    <option value={d}>{d}</option>
                                )
                            })}
                        </select>
                        <select aria-label='year' onChange={() => { setDob() }} className="select-date-forgot-username" name="dobYear" ref={register({ required: true })}>
                            {years.map(y => {
                                return (<option value={y}>{y}</option>)
                            })}
                        </select>
                        <input aria-label={t("dateOfBirth")} type="hidden" name="dob" ref={register({ required: true })}></input>
                    </div>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-create-account">
                        {t("emailAddress")}
                    </label>
                    <input aria-label={t("emailAddress")} type="text" id="emailAddress" name="emailAddress" ref={register({ required: false, pattern: { 
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        message: t("error_invalid_email")
                    } })} className="input-create-account" />
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-create-account">
                        {t("phoneNumber")}
                    </label>
                    <input aria-label={t("phoneNumber")} type="text" id="phoneNumber" name="phoneNumber" 
                        ref={register({ required: false,
                            pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im}
                        })} className="input-create-account" />
                </Grid.Row>

                <Grid.Row className="recaptcha-forgot-username text-center" centered>
                    <input aria-label='captchaInput' type="hidden" name="captcha" ref={register({ required: true })}></input>
                    <ReCAPTCHA
                        aria-label='captcha'
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={solveCaptcha}
                    />
                </Grid.Row>

                {errors.lastName &&
                    <Grid.Row centered>
                        <span className="error-text">{t("error_lastName_required")}</span>
                    </Grid.Row>
                }
                {errors.dob &&
                    <Grid.Row centered>
                        <span className="error-text">{t("error_dob_valid")}</span>
                    </Grid.Row>
                }
                {errors.emailAddress &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_invalid_email")}</span>
                </Grid.Row>
                }
                {errors.phoneNumber &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_invalid_phone")}</span>
                </Grid.Row>
                }
                {errors.missingSecondary &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_phone_or_email_required")}</span>
                </Grid.Row>
                }
                {errors.captcha &&
                    <Grid.Row centered>
                        <span className="error-text">{t("error_captcha_required")}</span>
                    </Grid.Row>
                }

                {!submitError
                    ?
                    <>
                    </>
                    :
                    <>
                        <Grid.Row>
                            <span className="letter-error-create-account">
                                {t('couldNotFindYourInformation')}</span>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '-15px' }}>
                            <span className="letter-error-create-account">
                                {t('pleaseCheckTheInformation')}</span>
                        </Grid.Row>
                    </>
                }


                <Grid.Row centered>
                    <button type="submit" className="button-circle-forgot-username">{t('submit')}</button>
                    <Link className="letter-cancel-forgot-username" to={'/signin'}>
                        {t('cancel')}
                    </Link>
                </Grid.Row>
            </Grid>
        </form>
    );
};

export default ForgotUsername;
