import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { setAuth } from "../../redux/auth";
import "./Profile.css";
import ProfileEmergencyContact from './ProfileEmergencyContact';
import ProfileInsuranceInformation from './ProfileInsuranceInformation';
import ProfilePatientProfile from './ProfilePatientProfile';
import api from '../../api';
import moment from 'moment';
import ReactGA from 'react-ga'

export default function ProfileHome(props) {
    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loadingActive, setLoadingActive] = useState();
    const [submittedDate, setSubmittedDate] = useState();
    function handleClickEdit() {
	ReactGA.event({ category: 'Profile', action: 'Begun Edit', label: 'Profile Edit'});
        props.nextPageProfileEdit();
    }

    function handleClickPassword() {
        history.replace("/profile-change-password");
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingActive(true);
        setSubmittedDate(undefined);
        api.patientGetGet({ PatientId: "", "GetProfile": true }).then((profResp) => {
            dispatch(setAuth({ profile: profResp.data.PatientData.profile }));
            if (profResp.data.PatientData.editSubmittedDate) {
                setSubmittedDate(profResp.data.PatientData.editSubmittedDate);
            }
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                props.setMessageError(err.response.data.message)
            }
            else {
                props.setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    }, []);

    return (
        <div>
            <Grid rows={3}>
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }
                <Grid columns='equal' divided inverted padded>
                    <Grid.Row style={{ marginBottom: 7 }}>
                        <Grid.Column className="width-column-tile-profile">
                            <span className="font-title" style={{ marginTop: 0 }}>{t('patientProfile')}</span>
                        </Grid.Column>
                        {submittedDate ?
                            <Grid.Column className="display-error-profile-up">
                                <span className="profileUnderReview">{t('profileSubmittedForReview', { date: moment(submittedDate).format('MM/DD/YY') })}</span>
                            </Grid.Column>
                            : null}
                        {auth.profile &&
                            <>
                                <Grid.Column className="buttons-profile" floated='right' textAlign='right' style={{ marginTop: '-6px', display: 'flex', flexDirection: 'row-reverse' }}>
                                    <button style={{ marginLeft: '18px', paddingLeft: 30, paddingRight: 30 }} onClick={handleClickEdit} className="button-circle profile-button" name="editProfile" id="editProfile">{t('editProfile')}</button>
                                    <button onClick={handleClickPassword} className="button-circle profile-button" name="changePassword" id="changePassword">{t('changePassword')}</button>
                                </Grid.Column>
                            </>
                        }
                    </Grid.Row>
                    {submittedDate ?
                        <span className="profileUnderReview display-error-profile-down">{t('profileSubmittedForReview', { date: moment(submittedDate).format('MM/DD/YY') })}</span>
                        : null}
                </Grid>

                {auth.profile &&
                    <>
                        <Grid.Row className="body-container">
                            <ProfilePatientProfile dataPatient={auth.profile.patientProfile} />
                            <div>
                                <Divider section />
                            </div>
                        </Grid.Row>
                        <span className="font-title" style={{ marginTop: 30 }}>{t('emergencyContactInformation')}</span>
                        <Grid.Row className="body-container">
                            <ProfileEmergencyContact dataEmergency={auth.profile.emergencyContactInformation} />
                        </Grid.Row>
                        <span className="font-title" style={{ marginTop: 30 }}>{t('insuranceInformation')}</span>
                        <Grid.Row className="body-container">
                            <ProfileInsuranceInformation dataPrimary={auth.profile.primaryInsuranceInformation} dataSecondary={auth.profile.secondaryInsuranceInformation} />
                        </Grid.Row>
                    </>

                }
            </Grid>
        </div>
    );
}
