import React, { useState, useEffect } from "react";
import "./ManageUsers.css";
import { Divider, Dimmer, Grid, Loader, } from 'semantic-ui-react';
import ManageUsersList from './ManageUsersList';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import api from '../../api'
import Toast from "../common/Toast";

export default function ManageUsersHome(props) {
    const { t } = useTranslation();
    const [sortColumn, setSortColumn] = useState("Username");
    const [sortDirection, setSortDirection] = useState("asc");
    const [manageUsers, setManageUsers] = useState();
    const [page, setPage] = useState(0);
    const [loadingActive, setLoadingActive] = useState();
    const [messageError, setMessageError] = useState();
    const [pagination, setPagination] = useState({ TotalPages: 0, ItemsPerPage: 3, PageNumber: 1 });

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            usernameFilter: undefined,
            roleFilter: undefined,
            patientIdFilter: undefined,
            status: undefined
        }
    });

    useEffect(() => {
        var sessionToken = window.localStorage.getItem('SessionToken');
        if (sessionToken && sessionToken != '-') {
            api.setSessionToken(sessionToken);
        }
        filterList();
    }, []);

    useEffect(() => {
        setPage(1);
        setLoadingActive(true);
        filterList();
    }, [sortColumn, sortDirection]);


    useEffect(() => {
        setLoadingActive(true);
        filterList();
    }, [page]);

    const clearFilters = () => {
        setValue("usernameFilter", undefined);
        setValue("roleFilter", undefined);
        setValue("patientIdFilter", undefined);
        setValue("status", undefined);
    }

    function actionButtonSubmit(sub) {
        filterList();
    }

    const filterList = () => {
        window.scrollTo(0, 0);
        let values = getValues();
        let hasValues = false;
        let filter = {
        };
        if (values.usernameFilter) {
            hasValues = true;
            filter.Username = values.usernameFilter;
        }
        if (values.roleFilter) {
            hasValues = true;
            filter.Role = values.roleFilter;
        }
        if (values.patientIdFilter) {
            hasValues = true;
            filter.PatientId = values.patientIdFilter;
        }
        if (values.status) {
            hasValues = true;
            filter.Status = values.status;
        }

        if (!hasValues) {
            setLoadingActive(false);
            setPagination({ PageNumber: 1, TotalPages: 1 });
            setManageUsers([]);
            return;
        }

        setLoadingActive(true);


        let payload = filter;
        payload.Pagination = { PageNumber: page, SortColumn: sortColumn, SortDirection: sortDirection };
        setLoadingActive(true);
        api.userGetGet(payload).then((userResp) => {
            if (!userResp.data.Pagination) {
                userResp.data.Pagination = { PageNumber: 1, TotalPages: 1 }
            }
            setPagination(userResp.data.Pagination);
            setManageUsers(userResp.data.Usernames);
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    }

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }
            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid stackable rows={3} centered>
                <Grid.Row className="fix-padding-row filters">
                    <Grid stackable columns={3}>
                        <Grid.Row className="fix-padding-row">
                            <Grid.Column>
                                <span className="letter-name-filter">{t('userName')}:</span>
                                <input className="width-input-username-filter-manage" name="usernameFilter" id="usernameFilter" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column>
                                <span className="letter-name-filter margin-title-patient">{t('patientId')}:</span>
                                <input name="patientIdFilter" id="patientIdFilter" className="margin-patient width-select-patientId-filter-manage" ref={register({ required: false })} />
                            </Grid.Column>

                            <Grid.Column className="lastColumn view-btn-filter-manage-user-computer" style={{ alignSelf: 'flex-end' }}>
                                <button className="button-circle size-button-filters size-btn-service-history" name="filter" id="filter">{t('filter')}</button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>

                <Grid.Row className="fix-padding-row filters">
                    <Grid stackable columns={3}>
                        <Grid.Row className="fix-padding-row">
                            <Grid.Column>
                                <span className="letter-name-filter">{t('role')}:</span>
                                <select className="margin-width-role" name="roleFilter" id="roleFilter" ref={register({ required: false })}>
                                    <option value=""></option>
                                    <option value="Patient">Patient</option>
                                </select>
                            </Grid.Column>

                            <Grid.Column>
                                <span className="letter-name-filter">{t('status')}:</span>
                                <select className="margin-width-status" name="status" id="status" ref={register({ required: false })}>
                                    <option value=""></option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </Grid.Column>

                            <Grid.Column className="view-btn-filter-manage-user lastColumn" style={{ alignSelf: 'flex-end' }}>
                                <button className="button-circle size-button-filters size-btn-service-history" name="filter" id="filter">{t('filter')}</button>
                            </Grid.Column>

                            <Grid.Column className="lastColumn">
                                <button onClick={clearFilters} className="button-circle size-button-filters size-btn-service-history" name="clearFilter" id="clearFilter">{t('clearFilter')}</button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Row >
                <Divider section />
                <Grid.Row className="fix-padding-row">
                    {manageUsers &&
                        <ManageUsersList pagination={pagination} setPage={setPage}
                            manageUsers={manageUsers} sortColumn={sortColumn} setSortColumn={setSortColumn}
                            sortDirection={sortDirection} setSortDirection={setSortDirection} />
                    }
                </Grid.Row>
            </Grid>
        </form>
    );
}
