import React, { useState } from "react";
import { Dimmer, Loader } from 'semantic-ui-react';
import Toast from "../common/Toast";
import ForgotUsername from "./ForgotUsername";
import UnauthenticatedAuthentication from "./UnauthenticatedAuthentication";
import UnauthenticatedAuthenticationTwo from "./UnauthenticatedAuthenticationTwo";
import MessageData from "./MessageData";
import api from "../../api";

const ComponentsForgotSurname = (props) => {
    const [page, setPage] = useState(1);
    const [username, setUsername] = useState("");
    const nextPageForgotSurname = () => setPage(p => p + 1);
    const lastPageForgotSurname = () => setPage(p => p - 1);
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [messageError, setMessageError] = useState();
    const [loadingActive, setLoadingActive] = useState();

    const sendPin = () => {
        let bodyContact = {
            Lookup: true,
            ChallengeResponse: {
                ContactOption: twoFactorSelected,
                Type: '2FA'
            }
        }
        setLoadingActive(true);
        api.patientValidatePost(bodyContact).then((response) => {
            setLoadingActive(false);
            nextPageForgotSurname();
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }

    const validatePin = (values) => {

        let body = {
            "Lookup": true,
            "ChallengeResponse": {
                "Type": "2FA",
                "MfaPin": values.authenticationCode
            }
        }

        setLoadingActive(true);
        api.patientValidatePost(body).then((response) => {
            setUsername(response.data.Username);
            setLoadingActive(false);
            nextPageForgotSurname();
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                if(err.response.data.ChallengeRequest){
                    lastPageForgotSurname();
                }
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            props.setOpenToast(true);
        });
    }


    return (
        <>
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            {page === 1 &&
                <ForgotUsername
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    nextPageForgotSurname={nextPageForgotSurname}
                    twoFactorOptions={twoFactorOptions} setTwoFactorOptions={setTwoFactorOptions} path={"/forgot-username"} />}

            {page === 2 &&
                <UnauthenticatedAuthentication lastPageForgotSurname={lastPageForgotSurname}
                    sendPin={sendPin} surname={true} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/forgot-username"} />}

            {page === 3 &&
                <UnauthenticatedAuthenticationTwo lastPageForgotSurname={lastPageForgotSurname}
                    validatePin={validatePin} surname={true} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setUsername={setUsername}
                    setTwoFactorOptions={setTwoFactorOptions} path={"/forgot-username"} />}

            {page === 4 &&
                <MessageData username={username} path={"/forgot-username"} />}
        </>
    );
};

export default ComponentsForgotSurname;