import React, { useState, useEffect } from "react";
import { Dimmer, Loader } from 'semantic-ui-react';
import ProfileManualUpload from "./ProfileManualUpload";
import AuthenticatedAuthentication from "../common/AuthenticatedAuthentication";
import AuthenticatedAuthenticationTwo from "../common/AuthenticatedAuthenticationTwo";
import ProfileUploadFile from "./ProfileUploadCard";
import ProfileConfirmEdit from "./ProfileConfirmEdit";
import api from "../../api";
import Toast from "../common/Toast";

const ComponentsUpload = (props) => {
    const [page, setPage] = useState('ProfileUploadFile');
    const setPageComponentsUpload = (nameComponent) => setPage(nameComponent);
    const [twoFactorOptions, setTwoFactorOptions] = useState();
    const [twoFactorSelected, setTwoFactorSelected] = useState();
    const [loadingActive, setLoadingActive] = useState();
    const [fileFront, setFileFront] = useState();
    const [fileBack, setFileBack] = useState();
    const [messageError, setMessageError] = useState();
    const [bodyManualUpload, setBodyManualUpload] = useState();
    const [primary, setPrimary] = useState(window.location.href.includes('primary'));
    const [uploadCard, setUploadCard] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading');
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const sendPin = () => {
        if (uploadCard) {
            let bodyContact = {
                InsuranceTier: primary ? "Primary" : "Secondary",
                ChallengeResponse: {
                    ContactOption: twoFactorSelected,
                    Type: '2FA'
                }
            }
            setLoadingActive(true);
            api.documentUploadGet(bodyContact).then((response) => {
                setLoadingActive(false);
                setPageComponentsUpload('AuthenticatedAuthenticationTwo');
            }).catch((err) => {
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
        } else {
            let bodyContact = { ...bodyManualUpload,
                ChallengeResponse: {
                    ContactOption: twoFactorSelected,
                    Type: '2FA'
                }
            }
            setLoadingActive(true);
            api.patientUpdatePost(bodyContact).then((response) => {
                setLoadingActive(false);
                setPageComponentsUpload('AuthenticatedAuthenticationTwo');
            }).catch((err) => {
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
        }
    }

    const validatePin = (values) => {
        if (!uploadCard) {
            let body = { ...bodyManualUpload,
                "ChallengeResponse": {
                    "Type": "2FA",
                    "MfaPin": values.authenticationCode
                }
            }

            setLoadingActive(true);
            api.patientUpdatePost(body).then((response) => {
                setLoadingActive(false);
            }).catch((err) => {
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
            });
        }
    }

    return (
        <>

            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>{loadingMessage}</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            {page === 'ProfileUploadFile' &&
                <ProfileUploadFile setUploadCard={setUploadCard} uploadCard={uploadCard}
                    primary={primary}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack}
                    setPageComponentsUpload={setPageComponentsUpload} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions} />}

            {page === 'ProfileManualUpload' &&
                <ProfileManualUpload setUploadCard={setUploadCard} uploadCard={uploadCard}
                    bodyManualUpload={bodyManualUpload} setBodyManualUpload={setBodyManualUpload}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions}
                    setPageComponentsUpload={setPageComponentsUpload} />}

            {page === 'AuthenticatedAuthentication' &&
                <AuthenticatedAuthentication
                    primary={primary}
                    type={'upload'}
                    messageError={messageError} setMessageError={setMessageError}
                    sendPin={sendPin}
                    twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions}
                    setPageComponentsUpload={setPageComponentsUpload} />}

            {page === 'AuthenticatedAuthenticationTwo' &&
                <AuthenticatedAuthenticationTwo setUploadCard={setUploadCard} uploadCard={uploadCard}
                    primary={primary}
                    fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack}
                    bodyManualUpload={bodyManualUpload} setBodyManualUpload={setBodyManualUpload}
                    type={'upload'}
                    setLoadingMessage={setLoadingMessage}
                    messageError={messageError} setMessageError={setMessageError}
                    openToast={props.openToast} setOpenToast={props.setOpenToast}
                    validatePin={validatePin} twoFactorSelected={twoFactorSelected}
                    setTwoFactorSelected={setTwoFactorSelected} twoFactorOptions={twoFactorOptions}
                    setTwoFactorOptions={setTwoFactorOptions}
                    setPageComponentsUpload={setPageComponentsUpload} />}

            {page === 'ProfilePasswordChange' &&
                <ProfileConfirmEdit fileFront={fileFront} setFileFront={setFileFront} fileBack={fileBack} setFileBack={setFileBack} type={'upload'} />}
        </>
    );
};

export default ComponentsUpload;