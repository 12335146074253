import React, { useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Dimmer, Grid, Loader, Icon } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import api from "../../api";
import "./Profile.css";

export default function ProfileUploadCard(props) {

    const [loadingActive, setLoadingActive] = useState();
    const { t } = useTranslation();
    const history = useHistory();

    function onChangeFront(e) {
        props.setFileFront(e.target.files[0]);
    }

    function onChangeBack(e) {
        props.setFileBack(e.target.files[0]);
    }

    function cancelImageFront() {
        props.setFileFront(null);
    }

    function cancelImageBack() {
        props.setFileBack(null)
    }

    function actionButtonSubmit() {
        let body = {
            InsuranceTier: props.primary ? "Primary" : "Secondary"
        };
        props.setUploadCard(true);
        setLoadingActive(true);
        api.documentUploadGet(body)
            .then((response) => {
                if (response.data.code === "Success") {
                    props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                    setLoadingActive(false);
                    props.setPageComponentsUpload('AuthenticatedAuthentication');
                }
                else {
                    setLoadingActive(false);
                }
            }).catch((err) => {
                setLoadingActive(false);
                if (err.response && err.response.data.message) {
                    props.setMessageError(err.response.data.message)
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            });

    }

    function redirectUploadManual() {
        props.setPageComponentsUpload('ProfileManualUpload');
    }

    function btnCancel() {
        history.replace('profile');
    }

    //<input type='file' onChange={this.onChange} />

    return (
        <Fragment>
            {loadingActive ?
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
                :
                <Grid rows={5} className="body-center">

                    <Grid.Row centered>
                        {window.location.href.includes('primary')
                            ? <span className="title-upload-card">{t('titleUploadFilePrimary')}</span>
                            : <span className="title-upload-card">{t('titleUploadFileSecondary')}</span>
                        }

                    </Grid.Row>

                    <Grid.Row>
                        <p className="letter-data-body-upload"><span>{t('textUploadFile')}</span></p>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid columns={2}>
                            <Grid.Column width={8}>
                                <Grid rows={3}>
                                    <Grid.Row>
                                        <div className="file-upload" type="file">
                                            <div className="image-upload-wrap">
                                                <input aria-label='uploadPrimary' className="file-upload-input" type='file' onChange={onChangeFront} accept="image/gif, image/jpeg, image/png, image/jpg, image/bmp, image/pdf" />
                                                <div className="drag-text">
                                                    <Icon className="upload-icon" name='cloud upload' size='huge' />
                                                    {!props.fileFront ?
                                                        <h3 role="heading" aria-level="1">{t('dragFile')}</h3>
                                                        : <h3 role="heading" aria-level="1"> {props.fileFront.name}</h3>}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Row>
                                    {props.fileFront && (
                                        <>
                                            <Grid.Row centered>
                                                <button onClick={cancelImageFront} className="button-circle" name="cancelFront" id="cancelFront" style={{ width: '19%', height: '37px' }}>{t('cancel')}</button>
                                            </Grid.Row>
                                        </>
                                    )}
                                    <Grid.Row centered>
                                        <strong>{t('frontSide')}</strong>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <Grid rows={3}>
                                    <Grid.Row>
                                        <div className="file-upload">
                                            <div className="image-upload-wrap">
                                                <input aria-label='uploadSecondary' className="file-upload-input" type='file' onChange={onChangeBack} accept="image/gif, image/jpeg, image/png, image/jpg, image/bmp, image/pdf" />
                                                <div className="drag-text">
                                                    <Icon className="upload-icon" name='cloud upload' size='huge' />
                                                    {!props.fileBack ?
                                                        <h3 role="heading" aria-level="2">{t('dragFile')}</h3>
                                                        : <h3 role="heading" aria-level="2"> {props.fileBack.name}</h3>}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Row>
                                    {props.fileBack && (
                                        <>
                                            <Grid.Row centered>
                                                <button onClick={cancelImageBack} className="button-circle" name="cancelBack" id="cancelBack" style={{ width: '19%', height: '37px' }}>{t('cancel')}</button>
                                            </Grid.Row>
                                        </>
                                    )}
                                    <Grid.Row centered>
                                        <strong>{t('backSide')}</strong>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid>
                    </Grid.Row>

                    <Grid.Row centered>
                        {props.fileFront && props.fileBack && (
                            <>
                                <button onClick={actionButtonSubmit} className=" button-circle" name="save" id="save" style={{ width: '22%', height: '37px', marginRight: '20px' }}>{t('save')}</button>
                            </>
                        )}
                        <button onClick={btnCancel} className="button-circle" name="cancel" id="cancel" style={{ width: '22%', height: '37px', marginLeft: '20px' }}>{t('cancel')}</button>
                    </Grid.Row>

                    <Grid.Row centered>
                        <span className="letter-upload-click">{t('notAbleToUpload')}?
                        {window.location.href.includes('primary')
                                ? <Link onClick={redirectUploadManual} className="notAbleToUploadLink"> {t('clickHere')} </Link>
                                : <Link onClick={redirectUploadManual} className="notAbleToUploadLink"> {t('clickHere')} </Link>
                            }


                            {t('forManualEntry')}</span>
                    </Grid.Row>
                </Grid>
            }
        </Fragment>
    );
}
