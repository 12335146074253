import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Dimmer, Grid, Loader } from 'semantic-ui-react';
import "./Profile.css";
import { ProfileEditEmergencyContact } from './ProfileEmergencyContact';
import { ProfileEditInsuranceInformation } from './ProfileInsuranceInformation';
import { ProfileEditPatientProfile } from './ProfilePatientProfile';
import { setAuth } from "../../redux/auth";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import api from "../../api";
import Toast from "../common/Toast";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export default function ProfileEditHome(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [datePrimary, setDatePrimary] = useState('');
    const [dateSecondary, setDateSecondary] = useState('');
    const [messageError, setMessageError] = useState();
    const { t } = useTranslation();

    const history = useHistory();
    const [loadingActive, setLoadingActive] = useState(false);

    function handleClickCancel() {
	ReactGA.event({ category: 'Profile', action: 'Cancelled Edit', label: 'Profile Edit'});
        props.lastPageProfileEdit();
    }

    function actionButtonSubmit(sub) {
	ReactGA.event({ category: 'Profile', action: 'Submitted Edit', label: 'Profile Edit'});
        let values = getValues();
        let body = {
            Profile: {
                "patientProfile": {
                    "firstName": values.firstName,
                    "lastName": values.lastNamePatient,
                    "phoneNumber": values.phoneNumberPatient,
                    "mobile": values.mobilePatient,
                    "address": values.addressPatient,
                    "city": values.cityPatient,
                    "state": values.statePatient,
                    "zipCode": values.zipCodePatient,
                    "emailId": values.emailAddressPatient,
                    "emailType": values.emailTypePatient
                },

                "emergencyContactInformation": {
                    "firstName": values.firstNameEmergencyContact,
                    "lastName": values.lastNameEmergencyContact,
                    "contactType": values.contactTypeEmergencyContact,
                    "phone": values.phoneEmergencyContact,
                    "phoneType": values.phoneTypeEmergencyContact,
                    "emailId": values.emailAddressEmergencyContact,
                    "emailType": values.emailTypeEmergencyContact
                }
            }
        }
        props.setBodyProfileEdit(body);
        setLoadingActive(true);
        api.patientUpdatePost(body)
            .then((response) => {
                if (response.data.ChallengeRequest) {
                    props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                    setLoadingActive(false);
                    props.nextPageProfileEdit();
                }
                else {
                    setLoadingActive(false);
                    props.redirectConfirm();
                    //Controlar error dependiendo el codigo (code)
                }
            }).catch((err) => {
                if (err.response && err.response.data.message) {
                    setMessageError(err.response.data.message)
                }
                else {
                    setMessageError(err.message)
                }
                props.setOpenToast(true);
                setLoadingActive(false);
            });
    }

    useEffect(() => {
        setLoadingActive(true);
        api.patientGetGet({ PatientId: "", "GetProfile": true }).then((profResp) => {
            dispatch(setAuth({ profile: profResp.data.PatientData.profile }));
            setLoadingActive(false);
        }).catch((err) => {
            setLoadingActive(false);
            if (err.response && err.response.data.message) {
                props.setMessageError(err.response.data.message)
            }
            else {
                props.setMessageError(err.message)
            }
            props.setOpenToast(true);
        })
    }, []);

    const { register, setValue, handleSubmit, errors, getValues, watch } = useForm({
        defaultValues: {
            firstName: auth.profile.patientProfile.firstName,
            lastNamePatient: auth.profile.patientProfile.lastName,
            phoneNumberPatient: auth.profile.patientProfile.phoneNumber,
            mobilePatient: auth.profile.patientProfile.mobile,
            addressPatient: auth.profile.patientProfile.address,
            cityPatient: auth.profile.patientProfile.city,
            statePatient: auth.profile.patientProfile.state,
            zipCodePatient: auth.profile.patientProfile.zipCode,
            emailAddressPatient: auth.profile.patientProfile.emailId,
            emailTypePatient: auth.profile.patientProfile.emailType,

            firstNameEmergencyContact: auth.profile.emergencyContactInformation.firstName,
            lastNameEmergencyContact: auth.profile.emergencyContactInformation.lastName,
            contactTypeEmergencyContact: auth.profile.emergencyContactInformation.contactType,
            phoneEmergencyContact: auth.profile.emergencyContactInformation.phone,
            phoneTypeEmergencyContact: auth.profile.emergencyContactInformation.phoneType,
            emailAddressEmergencyContact: auth.profile.emergencyContactInformation.emailId,
            emailTypeEmergencyContact: auth.profile.emergencyContactInformation.emailType,

            primaryInsuranceInformation: auth.profile.primaryInsuranceInformation.primary,
            policyNumberPrimaryInsuranceInformation: auth.profile.primaryInsuranceInformation.policyNumber,
            groupNumberPrimaryInsuranceInformation: auth.profile.primaryInsuranceInformation.groupNumber,

            secondaryInsuranceInformation: auth.profile.secondaryInsuranceInformation.primary,
            policyNumberSecondaryInsuranceInformation: auth.profile.secondaryInsuranceInformation.policyNumber,
            groupNumberSecondaryInsuranceInformation: auth.profile.secondaryInsuranceInformation.groupNumber,
        }
    });

    return (
        <form className="profile-edit-form" onSubmit={handleSubmit(actionButtonSubmit)}>
            {loadingActive &&
                <Dimmer active inverted>
                    <Loader size='big'>Loading</Loader>
                </Dimmer>
            }

            {props.openToast &&
                <Toast message={messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }

            <Grid rows={3}>
                <Grid columns='equal' divided inverted padded>
                    <Grid.Row style={{ marginBottom: 7 }}>
                        <Grid.Column className="width-column-tile-profile">
                            <span className="font-title" style={{ marginTop: 0 }}>{t('patientProfile')}</span>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>

                        <Grid.Column className="buttons-profile" floated='right' textAlign='right' style={{ marginTop: '-20px', display: 'flex', flexDirection: 'row-reverse' }} width={2}>
                            <button onClick={handleClickCancel} className="button-circle" name="cancel" id="cancel" style={{ width: '137px', height: '40px', marginRight: '10px' }}>{t('cancel')}</button>
                        </Grid.Column>

                        <Grid.Column className="buttons-profile" floated='right' textAlign='right' style={{ marginTop: '-20px', display: 'flex' }} width={2}>
                            <button type="submit" className="button-circle" name="submit" id="submit" style={{ width: '137px', height: '40px' }}>{t('submit')}</button>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>

                {auth.profile &&
                    <>
                        <Grid.Row className="body-container">
                            <ProfileEditPatientProfile setValue={setValue} errors={errors} dataPatient={auth.profile.patientProfile} register={register} watch={watch} />
                            <div>
                                <Divider section />
                            </div>
                        </Grid.Row>
                        <span className="font-title" style={{ marginTop: 30 }}>{t('emergencyContactInformation')}</span>
                        <Grid.Row className="body-container">
                            <ProfileEditEmergencyContact setValue={setValue} errors={errors} dataEmergency={auth.profile.emergencyContactInformation} register={register} watch={watch} />
                        </Grid.Row>
                        <span className="font-title" style={{ marginTop: 30 }}>{t('insuranceInformation')}</span>
                        <Grid.Row className="body-container">
                            <ProfileEditInsuranceInformation dataPrimary={auth.profile.primaryInsuranceInformation} dataSecondary={auth.profile.secondaryInsuranceInformation} />
                        </Grid.Row>
                    </>
                }
            </Grid>
        </form>
    );
}
