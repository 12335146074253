import _ from "lodash";
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Grid, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import api from "../../api";
import moment from 'moment';
import Toast from "../common/Toast";
import ReactGA from 'react-ga';

import "./CreateAccount.css";

export default function CreateAccount(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const [submitError, setSubmitError] = useState(false);
    const months = _.range(12);
    const days = _.range(1, 32);
    const years = _.range(1900, new Date().getFullYear() + 1);
    const [showMaintenance, setShowMaintenance] = useState(false);
    const [messageMaintenance, setMessageMaintenance] = useState();
    const [loadingActive, setLoadingActive] = useState();


    useEffect(() => {
        api.setSessionToken('-');
        loadWindowCheck();
    }, []);

    const loadWindowCheck = () => {
        setLoadingActive(true);
        api.checkWindows().then((resp) => {
            if (resp.data?.code === 'Closed') {
                setShowMaintenance(true);
                setMessageMaintenance(resp.data?.message);
            }
            setLoadingActive(false);
        }).catch((err) => {
            console.log(err)
            setLoadingActive(false);
        });
    }

    const { register, setValue, handleSubmit, errors, getValues, watch, clearError, setError } = useForm({
        defaultValues: {
            // patientId: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            emailAddress: undefined,
            dobDay: 1,
            dobMonth: 0,
            dobYear: 1900,
            dob: '1900-01-01',
            captcha: undefined,
            enterUserId: ''
        }
    });

    function actionButtonSubmit(sub) {
        let values = getValues();

        if (!values.phoneNumber && !values.emailAddress) {
            setError("missingSecondary", {
                type: "manual",
                message: "Either Phone Number or Email Address is mandatory."
            })
            return;
        }

        let body = {
            "DateOfBirth": values.dob,
            "FamilyName": values.lastName,
            "PatientId": "",
            "PhoneNumber": values.phoneNumber,
            "EmailAddress": values.emailAddress,
            "Lookup": true,
            "ChallengeResponse": {
                "Type": "CAPTCHA",
                "ReCAPTCHAResponse": { "Answer": values.captcha }
            }
        }
        props.setLoadingActive(true);
        ReactGA.event({category: 'Registration', action: 'Begin Registration', label: 'Begin Registration'});
        api.patientValidatePost(body)
            .then((response) => {
                if (response.data.code === "Success") {
                    props.setPatientId(values.patientId);
                    api.setSessionToken(response.data.SessionToken);
                    props.setTwoFactorOptions(response.data.ChallengeRequest.ContactOptionList);
                    props.nextPageRegister();
                    props.setLoadingActive(false);
                }
                else {
                    props.setLoadingActive(false);
                    window.grecaptcha.reset();
                    setSubmitError(true);
                }
            }).catch((err) => {
                props.setLoadingActive(false);
                window.grecaptcha.reset();
                ReactGA.event({category: 'Registration', action: 'Failed Registration', label: 'Failed Validation'});
                if (err.response && err.response.data.message) {
                    if (err.response.data.message === "Contact Options are empty.") {
                                props.setMessageError(t('no-contact-options'))
                    } else {
                            props.setMessageError(err.response.data.message)
                    }
                }
                else {
                    props.setMessageError(err.message)
                }
                props.setOpenToast(true);
            })
    }

    const solveCaptcha = (response) => {
        setValue('captcha', response);
    }

    const setDob = () => {
        let vals = getValues();
        let mom = moment({ y: vals.dobYear, M: vals.dobMonth, d: vals.dobDay });
        if (mom.isValid()) {
            setValue('dob', mom.format("YYYY-MM-DD"));
        } else {
            setValue('dob', undefined);
        }
    }

    return (
        <form className="forgotUsernameForm" onSubmit={handleSubmit(actionButtonSubmit)}>
                {loadingActive &&
                    <Dimmer active inverted>
                        <Loader size='big'>Loading</Loader>
                    </Dimmer>
                }

            {props.openToast &&
                <Toast message={props.messageError} type="error" openToast={props.openToast} setOpenToast={props.setOpenToast} />
            }
            <Grid columns={1} rows={7} className="body-grid-create-account">

            { showMaintenance &&
            <>
			    <Grid.Row centered>
			    <p className="maintenance-message">{messageMaintenance}</p>
			    </Grid.Row>
                            </>
            }

            { !showMaintenance &&
            <>

                <Grid.Row centered>
                    <span className="letter-title-create-account">{t("titleRegister")}</span>
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-create-account">
                        {t('lastName')} <Icon className="mandatoryField" name='asterisk' />
                    </label>
                    <input aria-label={t("lastName")} type="text" id="lastName" name="lastName" className="input-create-account" ref={register({ required: true })} />
                </Grid.Row>

                <Grid.Row>
                    <label className="letter-body-create-account">{t("dateOfBirth")} <Icon className="mandatoryField" name='asterisk' /></label>
                    <div className="padding-horizontal-date-create-account">
                        <select aria-label='month' onChange={() => { setDob() }} className="select-date-create-account" name="dobMonth" ref={register({ required: true })}>
                            {months.map(m => {
                                return (
                                    <option value={m}>{t('month_short_' + m)}</option>
                                )
                            })}
                        </select>
                        <select aria-label='day' onChange={() => { setDob() }} className="select-date-create-account" name="dobDay" ref={register({ required: true })}>
                            {days.map(d => {
                                return (
                                    <option value={d}>{d}</option>
                                )
                            })}
                        </select>
                        <select aria-label='year' onChange={() => { setDob() }} className="select-date-create-account" name="dobYear" ref={register({ required: true })}>
                            {years.map(y => {
                                return (<option value={y}>{y}</option>)
                            })}
                        </select>
                        <input aria-label={t("dateOfBirth")} type="hidden" name="dob" ref={register({ required: true })}></input>
                    </div>
                </Grid.Row>

                <Grid.Row>
                    <span className="letter-message-create-account">{t("register_choose_only_one")}</span>
                </Grid.Row>

                <Grid.Row>
                    <label className={watch("emailAddress") ? "letter-body-create-account label-disabled" : "letter-body-create-account"}>
                        {t("phoneNumber")}
                    </label>
                    <input aria-label={t("phoneNumber")} type="text" id="phoneNumber" name="phoneNumber" 
                        ref={register({ required: false,
                            pattern: { value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im}
                        })} 
                        disabled={!!watch("emailAddress")}
                        className="input-create-account" />
                </Grid.Row>

                <Grid.Row>
                    <label className={watch("phoneNumber") ? "letter-body-create-account label-disabled" : "letter-body-create-account"}>
                        {t("emailAddress")}
                    </label>
                    <input aria-label={t("emailAddress")} type="text" id="emailAddress" name="emailAddress" ref={register({ required: false, pattern: { 
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                        message: t("error_invalid_email")
                        }
                     })} 
                     disabled={!!watch("phoneNumber")}
                     className="input-create-account" />
                </Grid.Row>

                <Grid.Row className="recaptcha-create-account text-center" centered>
                    <input aria-label='captchaInput' type="hidden" name="captcha" ref={register({ required: true })}></input>
                    <ReCAPTCHA
                        aria-label='captcha'
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={solveCaptcha}
                    />
                </Grid.Row>

                {errors.lastName &&
                    <Grid.Row centered className="margin-error-create-account">
                        <span className="error-text">{t("error_lastName_required")}</span>
                    </Grid.Row>
                }
                {errors.dob &&
                    <Grid.Row centered className="margin-error-create-account">
                        <span className="error-text">{t("error_dob_valid")}</span>
                    </Grid.Row>
                }
                {errors.emailAddress &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_invalid_email")}</span>
                </Grid.Row>
                }
                {errors.phoneNumber &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_invalid_phone")}</span>
                </Grid.Row>
                }
                {errors.missingSecondary &&
                    <Grid.Row centered className="margin-error-create-account">
                    <span className="error-text">{t("error_phone_or_email_required")}</span>
                </Grid.Row>
                }
                {errors.captcha &&
                    <Grid.Row centered className="margin-error-create-account">
                        <span className="error-text">{t("error_captcha_required")}</span>
                    </Grid.Row>
                }

                {!submitError
                    ?
                    <>
                    </>
                    :
                    <>
                        <Grid.Row>
                            <span className="letter-error-create-account">
                                {t('couldNotFindYourInformation')}</span>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '-15px' }}>
                            <span className="letter-error-create-account">
                                {t('pleaseCheckTheInformation')}</span>
                        </Grid.Row>
                    </>
                }

                <Grid.Row centered>
                    <button type="submit" className="button-circle-forgot-username">{t('submit')}</button>
                    <Link className="letter-cancel-create-account" to={'/home'}>
                        {t('cancel')}
                    </Link>
                </Grid.Row>

                <Grid.Row centered className="mb-2">
                    <span className="letter-black-create-account">{t('doYouHaveAnAccount')}</span>
                    <span className="letter-redirect-login-create-account">
                        <Link to={"/signin"}>{t('login')}</Link>
                    </span>
                </Grid.Row>
            </>
	    }
            </Grid>
        </form>
    );
}
