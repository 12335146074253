import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Form, Grid, Radio } from 'semantic-ui-react';
import "./AuthenticatedAuthentication.css";
import { Link } from "react-router-dom";

export default function AuthenticatedAuthentication(props) {
    const { t } = useTranslation();
    const dataTest = [
        "317-xxx-xx34",
        "999-xxx-xx99",
        "JExxxxx@gxxx.com"
    ];

    const { register, setValue, handleSubmit, errors, getValues } = useForm({
        defaultValues: {
            optionSelected: props.twoFactorSelected
        }
    });

    useEffect(() => {
        if (props.twoFactorOptions) {
            props.setTwoFactorSelected(props.twoFactorOptions[0]);
        }
        //Solo test
        else {
            //props.setTwoFactorSelected(dataTest[0]);
        }
    }, [])

    function HandleChange(e, { value }) {
        props.setTwoFactorSelected(value);
    }

    function actionButtonSubmit() {
        props.sendPin();
    }

    function handleClickCancel() {
        if(props.type!='upload'){
            window.location.reload();
        }
    }

    const formFields = (props.twoFactorOptions ? props.twoFactorOptions : dataTest).map((dataName) => {
        return <Form.Field>
            <Radio
                className="color-radio color-checkbox"
                label={dataName}
                name={dataName}
                value={dataName}
                checked={props.twoFactorSelected === dataName}
                onChange={HandleChange}
            />
        </Form.Field>
    });

    return (
        <form className="form-authenticated-authentication" onSubmit={handleSubmit(actionButtonSubmit)}>
            <Grid rows={5} className="body-center-aunthentication">
                <Grid.Row centered>
                    <h1 className="letter-title-authenticated">Authentication</h1>
                </Grid.Row>

                <Grid.Row>
                    <span className="letter-data-body-authenticated">{t('textAuthentication')}</span>
                </Grid.Row>

                <Grid.Row className="center-checks-authenticated">
                    <Form>
                        {formFields}
                    </Form>
                </Grid.Row>

                <Grid.Row centered>
                    <button type="submit" className=" button-circle button-authenticated-authentication" name="continue" id="continue">{t('sendVerificationCode')}</button>
                    <Link to={'/profile'} onClick={handleClickCancel}
                        className="style-btn-cancel-authenticated" style={{ margin: '15px' }}>
                        {t('cancel')}</Link>
                </Grid.Row>

                <Grid.Row centered>
                    <p className="letter-data-body-authenticated"><span>{t('registerCondition')}</span></p>
                </Grid.Row>

            </Grid >
        </form>
    );
}
