import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Grid, Icon, Pagination, Table } from 'semantic-ui-react';

export default function ManageUsersList({ sortColumn, sortDirection, setSortColumn, setSortDirection, manageUsers, setPage, pagination }) {
    const history = useHistory();
    const { t } = useTranslation();


    function onChange(e, patientId) {
        switch (e.target.value) {
            case "audit":
                history.replace("/audit-log?patientId=" + patientId);
                break;
            case "profile":
                history.replace("/profile-A?patientId=" + patientId);
                break;
            case "history":
                history.replace("/service-history-A?patientId=" + patientId);
                break;
        }
    }

    const toggleSort = (column) => {
        if (sortColumn == column) {
            if (sortDirection == 'asc') {
                setSortDirection('desc');
            } else {
                setSortDirection('asc');
            }
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    }

    const onPageChange = (evt, data) => {
        setPage(data.activePage);
    }

    const TableRows = ({ item }) => {
        return <Table.Row key={item.PatientId}>
            <Table.Cell>{item.Username}</Table.Cell>
            <Table.Cell>{item.PatientId}</Table.Cell>
            <Table.Cell>{item.Role}</Table.Cell>
            <Table.Cell>{item.Status}</Table.Cell>
            <Table.Cell>
                <select onChange={e => onChange(e, item.PatientId)} style={{ width: '42%' }}>
                    <option value="...">...</option>
                    <option value="audit">Audit Log</option>
                    <option value="profile">Profile Page</option>
                    <option value="history">Service History</option>
                </select>
            </Table.Cell>
        </Table.Row>
    };

    return (
        <Grid padded='vertically' className="margin-list" style={{ marginLeft: 25 }}>
            <Grid.Column>
                <Table basic='very' fixed compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={() => { toggleSort('Username') }} className="letter-title-service-history-list">
                                {t('userName')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn == "Username" && sortDirection == 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn == "Username" && sortDirection == 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('PatientId') }} className="letter-title-service-history-list">
                                {t('patientId')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn == "PatientId" && sortDirection == 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn == "PatientId" && sortDirection == 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('Role') }} className="letter-title-service-history-list">
                                {t('role')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn == "Role" && sortDirection == 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn == "Role" && sortDirection == 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={() => { toggleSort('Status') }} className="letter-title-service-history-list">
                                {t('status')}
                                <span className="margin-icons-order">
                                    <Icon className={(sortColumn == "Status" && sortDirection == 'asc') ? 'style-icon-order position-icon-up-order ordered' : 'style-icon-order position-icon-up-order'} name='caret up' />
                                    <Icon className={(sortColumn == "Status" && sortDirection == 'desc') ? 'style-icon-order position-icon-down-order ordered' : 'style-icon-order position-icon-down-order'} name='caret down' />
                                </span>
                            </Table.HeaderCell>
                            <Table.HeaderCell className="letter-title-service-history-list">
                                {t('action')}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {manageUsers &&
                            manageUsers.map((user) => {
                                return (<TableRows key={user.PatientID} item={user}></TableRows>);
                            })
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row key="footer">
                            <Table.HeaderCell textAlign='left' style={{ position: 'relative' }}>
                                {pagination?<p className="pageCount">{t('pageNumber')} {pagination.PageNumber} {t('pageOf')} {pagination.TotalPages}</p>:null}
                                
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='4' textAlign='right' style={{ position: 'relative' }}>
                                <Pagination
                                    style={{ marginRight: '2em' }}
                                    activePage={pagination.PageNumber}
                                    totalPages={pagination.TotalPages}
                                    firstItem={null}
                                    lastItem={null}
                                    ellipsisItem={null}
                                    onPageChange={onPageChange}
                                    nextItem={t('next')}
                                    prevItem={t('prev')}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid>
    );
}
