import React, { useEffect, useState } from "react";
import "./MessageData.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from 'semantic-ui-react';
import api from "../../api";

const MessageData = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const actionButton = (url) => {
        history.replace(url);
    }

    return (
        <Grid columns={1} rows={3} className="body-grid-message-data">
            {props.username ?
                <>
                    <Grid.Row centered>
                        <p className="letter-message-data">
                            {t("yourUsername")}
                        </p>
                    </Grid.Row>

                    <Grid.Row centered>
                        <p className="letter-info-data">
                            {props.username ? props.username : 'USERNAME'}
                        </p>
                    </Grid.Row>
                </> :
                <Grid.Row centered>
                    <p className="letter-message-data" style={{ paddingLeft: 30, paddingRight: 30 }}>
                        {t("userNotFound")}
                    </p>
                </Grid.Row>
            }

            <Grid.Row centered style={{ paddingBottom: '50px' }}>
                <button className="button-circle-message-data" onClick={() => actionButton('/home')}>
                    {t("continue")}
                </button>
            </Grid.Row>
        </Grid >
    );
}

export default MessageData;