import { Link } from '@material-ui/core';
import React, { useState } from 'react'
import { Button, Header, Modal} from 'semantic-ui-react'
import api from '../../api';
import Toast from "../common/Toast";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

const InstamedSsoModal = ({label}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState();
    const [openToast, setOpenToast] = useState(false);
    const [imUrl, setImUrl] = useState();
    const { t } = useTranslation();

    const navigateToInstamed = () => {
        window.open(imUrl, '_blank', 'noreferrer');
        ReactGA.event({ category: 'Payments', action: 'Instamed', label: 'Navigating to Instamed'});
        setOpen(false);
    }

    const preNavigateToInstamed = () => {
        setOpen(true);
        setLoading(true);
        
        api.ssoInstamedUrl().then((response) => {
            let url = response?.data?.url;
            // window.open(url, '_blank', 'noreferrer');
            setImUrl(url);

            setLoading(false);
        }).catch((err) => {
            console.error(err)
            setLoading(false);
            setOpen(false);
            if (err.response && err.response.data.message) {
                setMessageError(err.response.data.message)
            }
            else {
                setMessageError(err.message)
            }
            setOpenToast(true);

        })

    }

    return (
        <>
            <Modal
                onClose = {() => setOpen(false)}
                onOpen = {() => preNavigateToInstamed()}
                open = {open}
                trigger={<Button class="letter-title-img-dashboard" as={Link}>{t('dashboard_cards_payment_title_img')}</Button>}
            >
                <Modal.Header>{t('leaving_patient_portal')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>{t('nav_instamed')}</Header>
                        <p>
                        {t('nav_instamed_detail')}
                        </p>
                        </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setOpen(false)}>{t('cancel')}</Button>
                    <Button
                        content={t('continue')}
                        onClick={() => navigateToInstamed()}
                        positive
                        loading={loading}
                    />

                </Modal.Actions>
            </Modal>
            {openToast &&
                <Toast message={messageError} type="error" openToast={openToast} setOpenToast={setOpenToast} />
            }
        </>
        
    )
}

export default InstamedSsoModal;